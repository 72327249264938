export default {
  workus: 'Work with us',
  apply: 'Apply',
  refer: 'Refer а Friend',
  send: 'Send',
  close: 'Close',
  txh_title: 'Thank you for your interest in RJ Games!',
  txh_text: 'We will consider your CV and give you<br/>feedback within 3 business days.',
  fio: 'Name',
  cv: 'CV/Portfolio link',
  contact: 'Contact information',
  contact_req: 'E-mail is required',
  comment: 'Comments',
  workuss: {
    pr: {
      text: 'World-class projects',
      description: 'We develop world-class games that compete with international market leaders and delight millions of users around the globe.'
    },
    int: {
      text: 'International company',
      description: 'Work from the head office in Cyprus or remotely from any part of the world.'
    },
    str: {
      text: 'Strong united team',
      description: 'RJ Games is a close-knit team of professionals and avid gamers. Mutual respect, friendliness, and trust are key elements of our DNA.'
    },
    fwd: {
      text: 'Always moving forward!',
      description: 'Tackling complex tasks makes us stronger and more experienced. Each new project challenges our team, and we are constantly raising the quality bar of our games.'
    },
    off: {
      text: 'Cool offices',
      description: 'Our cozy office includes kitchens, libraries, gaming consoles, and ping-pong tables, as well as spacious desks and powerful computers.'
    },
    tch: {
      text: 'Internal training',
      description: 'We appreciate openness and fresh ideas, so we regularly organize lectures and invite experts from other companies to share their experience.'
    }
  },
  workus_email: 'You want to work with us but do not see a suitable job opening?<br>Please send us your CV and cover letter to:',
  projects: 'Projects',
  projects_list: {
    puzzlebreakers: {
      description: '<p>Play match-3 puzzles to control your heroes and attack your enemies on the battlefield. In groundbreaking genre-changing Puzzle PRG - Puzzle Breakers! Your enemies move through the field and employ devious tactics to defeat you! Strong fighters try to engage you in melee, archers and mages attack from the top of towers and hiding places. Use your heroes and unique abilities to attack and control your enemies or to heal your allies. Destroy walls and covers, shoot at explosive barrels and wipe your pesky enemies off the map in a fit of righteous rage!</p><p>Genre: Puzzle RPG<br>Setting: Fantasy</p>',
      features: '<ul>\n' +
          '\t<li>Simple, intuitive gameplay with extraordinary depth for the genre.</li>\n' +
          '\t<li>Tons of exciting levels and lots of different ways to beat them.</li>\n' +
          '\t<li>Unique enemies with their own abilities and strategies.</li>\n' +
          '\t<li>Gripping PvP battles on a one-of-a-kind tactical battlefield.</li>\n' +
          '\t<li>Co-op game modes where you can play and chat with players from all over the world.</li>\n' +
          '\t<li>Clan battles where you can experience large-scale PvP action.</li>\n' +
          '\t<li>More than 100 unique heroes, giving you endless ways to create your dream teams.</li>\n' +
          '\t<li>An in-depth, branch-based hero leveling system.</li>\n' +
          '</ul>\n'
    },
    imperial: {
      description: '<p>Major armed conflicts and man-made disasters have caused the world order to collapse. Paramilitary factions are fighting for control of resources. Your homeland is on fire. Unite with friends to protect it and help it rise above the other states in a truly global strategy game!</p><p>Genre: Global strategy<br>Setting: The near future</p>',
      features: '<ul><li>Massive battles on a real world map with hundreds of thousands of players.</li><li>Realistic, high-quality graphics.</li><li>Clan wars for resources and territory, plus politics, diplomacy, and spy games.</li><li>Millions of players uniting into factions. Fierce battles for Moscow, Paris, London, New York, and hundreds of other major cities across the world.</li><li>A wide variety of in-game events with unique rewards, exploring maps alongside your brothers in arms.</li><li>The history of the game world is created by the players, right here and right now.</li></ul>'
    },
    dakota: {
      description: '<p>With its high mountains, pure rivers, and untouched forests, Dakota is certainly an ideal place to start your farm. The delightful charm of 19th-century North America, endless opportunities to manage and grow your farm, dozens of characters, and unique buildings make this game a real treat for connoisseurs of the farming genre.</p><p><br>Genre: Farming<br>Setting: 19th-century North America</p>',
      features: '<ul><li>More than 15 vast locations.</li><li>The option to go inside your house so you can decorate your estate inside and out.</li><li>More than 100 buildings and entourage objects.</li><li>More than 15 different types of animals with their own unique behaviors.</li><li>Extensive production system.</li><li>The option to change the landscape.</li><li>Hundreds of natural features which make every scene unique.</li></ul>'
    },
    undercontrol: {
      description: '<p>A global war established a new world order, and the world is now ruled by the side with the biggest guns. Lead a military base as you create a powerful army and find like-minded allies to redraw the map of the future world.</p><p>Genre: Global strategy<br>Setting: Military</p>',
      features: '<ul><li>Real-time PVP strategy.</li><li>Huge selection of units: infantry, heavy combat vehicles, artillery, air force.</li><li>Non-linear unit upgrade system.</li><li>Several PvP battle modes – 2 to 6 players in one battle.</li><li>Perfectly calibrated balance and intelligent opponent selection mechanism.</li><li>Clans and territory battles.</li><li>Monthly in-game events with unique rewards.</li></ul>'
    },
    dungeonbrawlers: {
      description: '<p>A long time ago, when kings ruled the world and handsome knights fought for ladies hearts, real heroes ventured into dungeons seeking gold and glory. Unite these heroes under your banners and set off on the most epic and exciting adventure ever.</p><p>Genre: Strategy</p><p>Setting: Fantasy</p>',
      features: '<ul><li>5 character classes: dangerous warriors, skilled hunters, powerful mages, stalwart defenders, and ingenious engineers.</li><li>More than 15 unique characters.</li><li>More than 100 types of enemies, from overgrown rats to steambots and megalomaniacal alchemists.</li><li>Battles against other players, daily rankings, and weekly competitions.</li><li>Clan battles and clan development.</li><li>90 achievements and dozens of titles.</li><li>Over 150 story levels in different parts of the kingdom.</li></ul>'
    },
    jackandjill: {
      description: '<p>Jack and Jill is the enchanting story of the adventures of little people. Help the whimsical Puppetville residents farm, decorate the town, and help brave Jack find his beautiful Jill.</p><p>Setting: Fairytale</p>',
      features: '<ul><li>More than 20 characters with their own stories.</li><li>More than 120 exciting quests.</li><li>A fully interactive world where you can interact with everything you see.</li><li>More than 50 buildings and entourage objects.</li><li>More than 100 landscape elements.</li><li>Thorough, intuitive crafting and construction system.</li><li>The option to change the characters\' appearance, and dozens of outfits to suit every taste.</li></ul>'
    },
    underfire: {
      description: '<p>After reaching far-off worlds, humanity faced things that have changed it forever. The horrific bugs of the Swarm, the rise of artificial intelligence, mutants, and its own greed challenged humanity. Join the Confederation of Independent Worlds, build your own starbase, and join the war for the future of all mankind.</p><p>Setting: Sci-Fi</p>',
      features: '<ul><li>A fascinating story with 6 plot episodes and over 120 missions.</li><li>More than 100 unique soldiers and opponents with individual behaviour and special skills.</li><li>Your own space fleet and large-scale battles for space colonies.</li><li>Advanced unit and hero upgrade system with over 150 specialisations.</li><li>Multiple PvP modes.</li><li>A well-developed urban planning system with more than 50 buildings in the colony and in orbit.</li><li>Dynamic and spectacular battles where your every decision can turn things around and bring you victory.</li></ul>'
    },
    undersiege: {
      description: '<p>Under Siege is our take on the tower defense genre. Become the leader of a Kingdom and withstand dozens of violent sieges with the best warriors and famous heroes at your service. Use traps, strategic tricks, and powerful magic. Defend your right to the crown!</p><p>Genre: Tower Defense</p>',
      features: '<ul><li>Tower defense in a classic fantasy setting.</li><li>Dozens of unique traps and soldiers, powerful magic, and brave heroes will help you build an insurmountable defense.</li><li>Over a hundred unique enemies, from evil goblins and mad gnomes to demons, zombies, and pink elephants.</li><li>Fire, explosions, lightning, and the suffering of your enemies - hundreds of colorful effects, not for the faint-hearted.</li><li>4 story campaigns and over 50 missions with varying difficulty levels and diverse landscapes, from tropical islands to dungeons full of treasure.</li><li>Tournaments and competitions against players from around the world.</li><li>More than 10 game modes that will blow your mind!</li></ul>'
    },
  },
  play_on: 'PLAY ON:',
  techs: 'Technologies:',
  features: 'GAME FEATURES',
  company: 'ABOUT US',
  facts: {
    years: 'YEARS<br/>' +
        ' IN THE INDUSTRY',
    projects: 'LARGE<br/>' +
        ' PROJECTS',
    employees: 'EMPLOYEES<br/>' +
        ' IN THE COMPANY'
  },
  jobs: 'Jobs',
  slogan: 'among the stars<br/> the best games are born!',
  occupation: [
    '',
    'Full-time',
    'Half-time',
    'Outsource'
  ],
  location: [
    '',
    'Moscow',
    'Limassol',
    'Remote'
  ],
  deps: {
    "cat_1": {
      "name": "marketing",
      "description": "<p><strong>We believe that a good game only a part of success. Acquisition and community can turn a good game into legendary one.</strong></p><ul><li>Work with any acquisition channel.</li><li>We are open to new creative ways in marketing an acquisition.</li><li>Constantly looking for new ways in acquisition and engagement.</li><li>We keep our community friendly and listen to our players.</li></ul>"
    },
    "cat_2": {
      "name": "gamedesign",
      "description": "<p><strong>In our game design process, we are guided by the systematic approach. We study each idea closely, select the best, and use them as the foundation to create interesting games with in-depth mechanics.</strong></p><ul><li>Creative and out-of-the-box tasks dealing with game logic, economics, and analytics that won’t leave you any time to get bored.</li><li>A well-designed pipeline structure and an appropriate balance between creativity and use of existing practices.</li><li>The ability to influence the gaming product: responsive management and producers who are always ready to pay attention to interesting, informed ideas.</li></ul>"
    },
    "cat_3": {
      "name": "art",
      "description": "<p><strong>RJ Games artists are real pros who create living worlds and memorable characters, pushing each other to achieve more.</strong></p><ul><li>Be it fantasy, military, or casual, artists can choose which projects they work on to suit their unique style.</li><li>The opportunity to study new facets of graphics and work on interesting tasks in concept art, modelling, animation, and UI.</li><li>Mutual support and healthy competition on a team of masters of their craft for a result that you'll be proud of.</li></ul>"
    },
    "cat_4": {
      "name": "development",
      "description": "<p><strong>Our developers handle complicated tasks and achieve their goals to delight millions of players all around the world with games from our studio.</strong></p><ul><li>The developers choose their own methods to solve problems, following the one basic principle that behind each line of code should be a fully-developed thought.</li><li>Exchanging experience, studying new technologies together, and keeping an open-minded attitude to adopting fresh viewpoints in an atmosphere of professional trust.</li><li>Our experts do not turn development into a cult, but also do not take their work lightly – they choose the right tools and create reliable products.</li></ul>"
    },
    "cat_5": {
      "name": "administrative",
      "description": "<p><strong>We enjoy creating a cozy and pleasant atmosphere in our offices to ensure that anyone on the team is comfortable both working or relaxing amongst colleagues.</strong></p><ul><li>A flexible approach to workflow – we are focused on results, not procedures for the sake of procedures.</li><li>A close-knit team where we always support each other – you’ll never hear us say “that’s your problem, not mine”.</li><li>Simplicity and transparency – we strive to make every complicated process simple, understandable, and predictable for our employees.</li></ul>"
    }
  },
  contacts: {
    title: 'Contacts',
    cy: {
      location: 'Limassol, Cyprus',
      address: 'RJ Games LTD, HE 355950, 3 Agiou Neofytou, 3036'
    },
    ru: {
      location: 'Moscow, Russia',
      address: 'Bolshaya Tatarskaya Ulitsa, 35 bld.7-9'
    },
  },
  terms: 'Terms of service',
  privacy: 'Privacy policy',
  social: 'Follow us on social media:',
  social_project: 'Community:',
  support: 'Support',
  copyright: 'RJ Games LTD©️, '+ new Date().getFullYear(),
  next_game: 'Next game',
  search: 'SEARCH...',
  chosen_deps: 'Chosen departments:',
  all_deps: 'All departments',
  become_team: 'Work at RJ Games!',
  become_jobs: 'Latest Job Opportunities',
  share_job: 'Share job:',
  referral_program: 'Referral program',
  ref_desc: 'RJ Games is a united team of professionals and avid gamers. The key values of our culture are love for our work, mutual respect and trust.<br/><br/>A lot of our employees join our team as referrals (both external and internal). By referring professionals you help them to join a team of like-minded people and you also get a pleasant financial bonus!',
  ref_reward: 'The reward for a referral',
  ref_rub_any: 'for any position',
  ref_rub_special: 'for a hot vacancy<br/>that is marked by the',
  ref_bonus: 'Bonus payment',
  ref_bonus_text: '<li>The referral is accepted under the condition that the company has never had any previous contact with the referred candidate and he/she has never been interviewed before.</li><li>An accepted referral is valid for 1 year since the moment of filling out the special form.</li><li>A bonus for referring a relevant specialist is paid in the amount of 100% after the successful completion of the probation period.</li>',
  ref_howto: 'How to participate in the program?',
  ref_howto_text: 'If you want to recommend a specialist, please fill out the <a target="_blank" href="https://forms.gle/WX8vZTTjJApHn6jq9">form</a>. If you are not sure which position is suitable for your referral, just leave the field blank and we will find a good option.<br/><br/>Job openings: the list of current jobs can be found in the <a href="/vacs">Jobs</a> section.<br/><br/>Please send your inquiries concerning the bonus program to <a href="mailto:job@rj-games.com">job@rj-games.com</a> and we will be happy to answer all your questions.',
  ref_vacs: 'Jobs',
  job: {
    salary: 'Salary',
    schedule: 'Schedule',
    location: 'Location'
  },
  available: 'Play now:',
  available2: 'Play now!',
  more: 'Learn about the game',
  play_in: 'play Puzzle Breakers:',
  closed: 'This game has already been closed but will forever remain an important part of our history.<br>For new' +
      ' adventures check out <b><a href="https://play.google.com/store/apps/details?id=com.playrix.puzzlebreakers" target="_blank">Puzzle Breakers!</a></b>'
};
