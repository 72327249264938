import * as React from 'react';
import Slider from "../components/home/Slider";

interface MyProps {
  strings: any,
  language: any
};

class Company extends React.Component<MyProps> {

  createMarkup(string:string) {
    return {__html: string};
  }

  calculateAge(birthday) {
    const ageDifMs = Date.now() - birthday;
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public render() {
    return (
        <div id="Company">
          <div id="slider-content" className="content">
            <h2>{this.props.strings.company}</h2>
            <div className="inner">
              <div className="bottom">
                <div className="achive">
                  <span className="number">{ this.calculateAge(1341100800000) }</span>
                  <span className="text"  dangerouslySetInnerHTML={this.createMarkup(this.props.strings.facts.years)} />
                </div>
                <div className="achive">
                  <span className="number">8</span>
                  <span className="text"  dangerouslySetInnerHTML={this.createMarkup(this.props.strings.facts.projects)} />
                </div>
                <div className="achive">
                  <span className="number">150+</span>
                  <span className="text"  dangerouslySetInnerHTML={this.createMarkup(this.props.strings.facts.employees)} />
                </div>
              </div>
              <div className="body">
                <Slider />
              </div>

            </div>
          </div>
        </div>
    );
  }
}

export default Company;
