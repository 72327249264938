import * as React from 'react';
import config from "../mixins/config";

interface MyProps {
  strings: any,
  language: any,
  project: any
};

class Footer extends React.Component<MyProps> {
  public render() {
    let _self = this;
    let support;
    const projects = config.projects[_self.props.project];
    let footerClass: string = '';
    let social = <div className="social">
      {this.props.strings.social}
      <div className="links">
        <div className="in"><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/rj-games/about/">&nbsp;</a>
        </div>
        <div className="arts"><a rel="noopener noreferrer" target="_blank" href="https://www.artstation.com/rj-games">&nbsp;</a></div>
      </div>
    </div>;
    if (this.props.project !== '') {
      footerClass = 'project';
      const projectLoc = projects.social_footer;

      const links = projectLoc.map((item) => {
        return <div key={Object.keys(item)[0]} className={Object.keys(item)[0]}><a rel="noopener noreferrer" target="_blank"
                                                                                   href={item[Object.keys(item)[0]]}>&nbsp;</a></div>;
      });
      const language = this.props.language === 'en' ? 'en-us' : 'ru';

      support = !!projects.support ? <div className="support"><a rel="noopener noreferrer" target="_blank"
                                                 href={projects.support + language}>{_self.props.strings.support}</a><br/><br/></div> : '';
      const community = projects.social_footer.length > 0 ? this.props.strings.social_project : '';

      social = <div className="social">
        <div>{community}</div>
        <div className="links">
          {links}
        </div>
      </div>;
    }
    return (
        <div id="Footer" className={footerClass}>
          <div className="conent">
            <div className="inner">
              <div className="privacy">
                <span>{this.props.strings.copyright}</span>
              </div>
              {social}
              {support}
            </div>
          </div>
        </div>
    );
  }
}

export default Footer;
