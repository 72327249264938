import * as React from 'react';
import { Link } from 'react-router-dom';
import config from '../mixins/config';
import ExternalLink from './ExternalLink';

interface MyProps {
    strings: any,
    language: any
}

class Projects extends React.Component<MyProps> {
    public render() {
        let links: any = [];
        let projects: any = config.projects;
        console.log(projects);
        for (let i = 0; i < projects.length; i++) {
            let project = projects[i].front;
            let social: any = [];
            for (let j = 0; j < project.social.length; j++) {
                social.push(<img alt={ 'social' } key={ j } src={ '/img/social/' + project.social[j] + '.svg' }/>);
            }

            links.push(<a key={ i } className={ `project project_${ i }` } href={ `/project/` + projects[i].link + '?lan=' + this.props.language }>
                <div className="name">{ project.name }</div>
                <div className="genre">{ project.genre }</div>
                <div className="status">{ project.status }</div>
                <div className="social"></div>
                <div className="hover"/>
                <div className="triangle"/>
                <div className={"footer footer_"+i}>
                    <div className="about">{ this.props.strings.more }</div>
                    {i === 0 &&
                        <div className="button_block">
                            <div className="already">{ this.props.strings.available }</div>
                            {project.button_link_ios &&
                                <div className="button">
                                    <ExternalLink to={ project.button_link_ios } children={<img src={"/img/btn_play_ios_black.png"} alt=""/>} />
                                </div>
                            }
                            <div className="button">
                                <ExternalLink to={ project.button_link } children={<img src={"/img/btn_play_gp_black.png"} alt=""/>} />
                            </div>
                        </div>
                    }
                    {i === 1 &&
                    <div className="button_block">
                        <div className="already">{ this.props.strings.available }</div>
                        <div className="button">
                            <ExternalLink to={ project.button_link } children={<img src={"/img/btn_play_en.png"} alt=""/>} />
                        </div>
                    </div>
                    }
                </div>
            </a>);
        }

        return (

            <div id="Projects">
                <div className="content">
                    <h2>{ this.props.strings.projects }</h2>
                    <div className="inner flex-container">
                        { links }
                    </div>
                </div>
            </div>
        );
    }
}

export default Projects;

