import * as React from 'react';

interface MyProps {
    strings: any,
    language: any
};

class Contacts extends React.Component<MyProps> {
    public render() {
        return (
            <div id="Contacts">
                <div className="conent">
                    <h2>{ this.props.strings.contacts.title }</h2>
                    <div className="inner">
                        <div className="limasol">
                            <a rel="noopener noreferrer" target="_blank"
                               href="https://www.google.com/maps/place/RJ+Games/@34.6746251,33.0435362,21z/data=!4m13!1m7!3m6!1s0x14e7330315b07343:0xa1e3cab05eb5f2d6!2sAgiou+Neofytou+3,+Limassol+3036,+Cyprus!3b1!8m2!3d34.674678!4d33.043594!3m4!1s0x14e7330315b07343:0x61eb3f14d77d650b!8m2!3d34.6746706!4d33.0434811">
                                <div className="location">
                                    <span>{ this.props.strings.contacts.cy.location }</span>
                                </div>
                                <div className="address">
                                    { this.props.strings.contacts.cy.address }
                                </div>
                                <div className="map">
                                    <img src="/img/map_cy.png" alt=""/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacts;
