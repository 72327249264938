import * as React from 'react';
import { createCn } from 'bem-react-classname';
import { Link } from 'react-router-dom';

interface Vacancies {
    dep: number,
    description_en: string
    id: number
    location: number[]
    occupation: number
    salary_en: string
    salary_ru: string
    title_en: string
    hot: boolean
    title_ru: string
}


interface MyProps {
    strings: any,
    language: string,
    vacancies: any
};

const cn = createCn('vacancies');

export const DepVacs = React.memo<MyProps>((props) => {
    const {vacancies} = props;
    let title = 'title_' + props.language;

    return (
        <React.Fragment>
            {vacancies && Object.keys(vacancies).map((index) => {
                const vacs = vacancies[index].map((item:Vacancies) => {
                    let loc;
                    let job = item.location.sort();
                    let options = [
                        {value: 1, label: props.strings.location[1]},
                        {value: 2, label: props.strings.location[2]},
                        {value: 3, label: props.strings.location[3]},
                    ];
                    if (Array.isArray(job)) {
                        loc = job.map((el) => {
                            for (let i = 0; i < options.length; i++) {
                                if (el === options[i].value) {
                                    return options[i].label;
                                }
                            }
                        }).join(' / ');
                    } else {
                        for(let i = 0; i < options.length; i++) {
                            if (options[i].value === job) {
                                loc = options[i].label;
                            }
                        }
                    }
                    return (
                        <div key={item.id} className={cn("row")}>
                            <div className={cn("title")}>
                                {
                                    item.hot && <div className={cn("title-img")}><img src='/img/vac_icons/hot_19.png' /></div>
                                        }
                                        <div className={cn("title-link")}><Link key={item.id} className={`job_link`} to={`/job/${item.id}`}>{item[title]}</Link></div></div>
                            <div className={cn("occupation")}>{props.strings.occupation[item.occupation]}</div>
                        </div>
                    );
                })
                return (
                    <React.Fragment>
                        <div className={cn('department')}>
                            <div className={cn('icon')}><img src={"/img/vac_icons/" + index + ".png"} alt=""/></div>
                            <div className={cn('text')}>{index.toUpperCase()}</div>
                        </div>
                        <div>{ vacs }</div>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
});
