interface config {
    projects: any,
    slides: any
}

const config: config = {
    projects: [
        {
            id: 0,
            link: 'puzzlebreakers',
            front: {
                status: '',
                name: 'puzzle breakers',
                genre: 'Puzzle RPG',
                social: ['android_proj'],
                softlaunch: 'SOFT LAUNCH 2020',
                button_link: 'https://play.google.com/store/apps/details?id=com.playrix.puzzlebreakers',
                button_link_ios: 'https://apps.apple.com/us/app/puzzle-breakers-rpg-online/id1523290976'
            },
            social: [{ 'android': 'https://play.google.com/store/apps/details?id=com.playrix.puzzlebreakers' }],
            social_footer: [{'dd': 'https://discord.com/invite/HjWjEa8saM'}, { 'fb': 'https://www.facebook.com/PuzzleBreakers/' }],
            support: 'https://support.puzzle-breakers.com/hc/',
            tech: ['Unity 3D', 'C#'],
            main_image: '/img/projects/pb/big_art_pb.jpg',
            desc_image: '/img/projects/pb/about_pb.jpg',
            bottom_image: '/img/projects/pb/properties_pb1.png',
            bottom_videos: [
                [
                    // {type: 'video/ogg; codecs="theora, vorbis"', src: '/video/jihn_b.ogg'},
                    { type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"', src: '/video/jihn_2.mp4' }
                ],
                [
                    { type: 'video/ogg; codecs="theora, vorbis"', src: '/video/elf_b.ogg' },
                    { type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"', src: '/video/elf_b.mp4' }],
                [
                    // {type: 'video/ogg; codecs="theora, vorbis"', src: '/video/elf_b.ogg'},
                    { type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"', src: '/video/dwarf_1.mp4' }]

                // '/site/public/video/elf_b.mp4',
                // '/site/public/video/jinn_b.mp4'
                // {type: 'video/webm; codecs="vp8, vorbis"', src: '/video/undercontrol_en_1920x647.webm'},
                // {type: 'video/ogg; codecs="theora, vorbis"', src: '/video/undercontrol_en_1920x647.ogv'}
            ],
            slider: [
                '/img/projects/pb/slider/pb_screen_1.jpg',
                '/img/projects/pb/slider/pb_screen_2.jpg',
                '/img/projects/pb/slider/pb_screen_3.jpg',
                '/img/projects/pb/slider/pb_screen_4.jpg',
                '/img/projects/pb/slider/pb_screen_5.jpg'
            ],
            videos: []
        },
        {
            id: 3,
            link: 'undercontrol',
            front: {
                status: '',
                name: 'under control',
                genre: 'strategy',
                social: ['bw_proj'],
                softlaunch: '',
                button_link: 'https://undercontrolgame.com/en'
            },
            social: [
                { 'bw': 'http://undercontrolgame.com/' }],
            social_footer: [],
            support: '',
            tech: ['C++', 'Python', 'Flash', 'PHP'],
            main_image: '/img/projects/uc/new_skin_game_page_uc.jpg',
            desc_image: '/img/projects/uc/about_uc.jpg',
            bottom_image: '',
            bottom_videos: [],
            slider: [
                '/img/projects/uc/slider/uc_screen_1.jpg',
                '/img/projects/uc/slider/uc_screen_2.jpg',
                '/img/projects/uc/slider/uc_screen_3.jpg',
                '/img/projects/uc/slider/uc_screen_5.jpg'
            ],
            videos: [
                { type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"', src: '/video/undercontrol_en_1920x647.mp4' },
                { type: 'video/webm; codecs="vp8, vorbis"', src: '/video/undercontrol_en_1920x647.webm' },
                { type: 'video/ogg; codecs="theora, vorbis"', src: '/video/undercontrol_en_1920x647.ogv' }
            ]
        },
        {
            id: 2,
            link: 'dakota',
            front: {
                status: '',
                name: 'dakota',
                genre: 'casual',
                social: ['bw_proj'],
                softlaunch: ''
            },
            social: [],
            social_footer: [],
            support: '',
            tech: ['C++', 'Python', 'Flash'],
            main_image: '/img/projects/dk/dk_big_art.jpg',
            desc_image: '/img/projects/dk/about_dk.jpg',
            bottom_image: '/img/projects/dk/properties_dk.jpg',
            bottom_videos: [],
            slider: [
                '/img/projects/dk/slider/dk_screen_1.jpg',
                '/img/projects/dk/slider/dk_screen_2.jpg',
                '/img/projects/dk/slider/dk_screen_3.jpg',
                '/img/projects/dk/slider/dk_screen_4.jpg',
                '/img/projects/dk/slider/dk_screen_5.jpg'
            ],
            videos: []
        },
        {
            id: 1,
            link: 'imperial',
            front: {
                status: '',
                name: 'imperial',
                genre: 'strategy',
                social: [],
                softlaunch: '',
                button_link: ''
            },
            social: [],
            social_footer: [],
            support: '',
            tech: ['C++', 'Cocos2d-x', 'PHP'],
            main_image: '/img/projects/imp/imp_big_art.jpg',
            desc_image: '/img/projects/imp/about_imp.jpg',
            bottom_image: '/img/projects/imp/properties_imp.jpg',
            bottom_videos: [],
            slider: [
                '/img/projects/imp/slider/imp_screen_2.jpg',
                '/img/projects/imp/slider/imp_screen_3.jpg',
                '/img/projects/imp/slider/imp_screen_4.jpg',
                '/img/projects/imp/slider/imp_screen_5.jpg'
            ],
            videos: []
        },

        {
            id: 4,
            link: 'dungeonbrawlers',
            front: {
                status: '',
                name: 'dungeon brawlers',
                genre: 'strategy',
                social: ['bw_proj'],
                softlaunch: '',
                button_link: ''
            },
            social: [],
            social_footer: [],
            support: '',
            tech: ['C++', 'Python', 'Flash'],
            main_image: '/img/projects/db/db_big_art.jpg',
            desc_image: '/img/projects/db/about_db.jpg',
            bottom_image: '/img/projects/db/properties_db.jpg',
            bottom_videos: [],
            slider: [
                '/img/projects/db/slider/db_screen_1.jpg',
                '/img/projects/db/slider/db_screen_2.jpg',
                '/img/projects/db/slider/db_screen_3.jpg',
                '/img/projects/db/slider/db_screen_4.jpg',
                '/img/projects/db/slider/db_screen_5.jpg'
            ],
            videos: []
        },
        {
            id: 5,
            link: 'jackandjill',
            front: {
                status: '',
                name: 'jack and jill',
                genre: 'casual',
                social: ['bw_proj'],
                softlaunch: '',
                button_link: ''
            },
            social: [],
            social_footer: [],
            support: '',
            tech: ['C++', 'Python', 'Flash'],
            main_image: '/img/projects/jj/jj_big_art.jpg',
            desc_image: '/img/projects/jj/about_jj.jpg',
            bottom_image: '/img/projects/jj/properties_jj.jpg',
            bottom_videos: [],
            slider: [
                '/img/projects/jj/slider/jj_screen_1.jpg',
                '/img/projects/jj/slider/jj_screen_2.jpg',
                '/img/projects/jj/slider/jj_screen_3.jpg',
                '/img/projects/jj/slider/jj_screen_5.jpg'
            ],
            videos: []
        },
        {
            id: 6,
            link: 'underfire',
            front: {
                status: '',
                name: 'under fire',
                genre: 'strategy',
                social: ['bw_proj'],
                softlaunch: '',
                button_link: ''
            },
            social: [],
            social_footer: [],
            support: '',
            tech: ['C++', 'Python', 'Flash'],
            main_image: '/img/projects/uf/uf_big_art.jpg',
            desc_image: '/img/projects/uf/about_uf.jpg',
            bottom_image: '/img/projects/uf/properties_uf.jpg',
            bottom_videos: [],
            slider: [
                '/img/projects/uf/slider/uf_screen_3.jpg',
                '/img/projects/uf/slider/uf_screen_4.jpg'
            ],
            videos: []
        },
        {
            id: 7,
            link: 'undersiege',
            front: {
                status: '',
                name: 'under siege',
                genre: 'strategy',
                social: ['bw_proj'],
                softlaunch: '',
                button_link: ''
            },
            social: [],
            social_footer: [],
            support: '',
            tech: ['C++', 'Python', 'Flash'],
            main_image: '/img/projects/us/us_big_art.jpg',
            desc_image: '/img/projects/us/about_us.jpg',
            bottom_image: '/img/projects/us/properties_us.jpg',
            bottom_videos: [],
            slider: [
                '/img/projects/us/slider/us_screen_3.jpg',
                '/img/projects/us/slider/us_screen_4.jpg',
                '/img/projects/us/slider/us_screen_5.jpg'
            ],
            videos: []
        }
    ],
    slides: [
        '/img/slides/0.jpg',
        '/img/slides/1.jpg',
        '/img/slides/2.jpg',
        '/img/slides/3.jpg',
        '/img/slides/4.jpg',
        '/img/slides/5.jpg',
        '/img/slides/6.jpg',
        '/img/slides/7.jpg',
        '/img/slides/8.jpg',
        '/img/slides/10.jpg',
        '/img/slides/11.jpg',
        '/img/slides/12.jpg',
        '/img/slides/13.jpg'
    ]
};

export default config;
