import * as React from 'react';

interface MyProps {
  strings: any,
  language: any
};

class Workus extends React.Component<MyProps> {
  public render() {
    return (
        <div id="Workus">
          <div className="content">
            <h2>{this.props.strings.workus}</h2>
            <div className="inner flex-container">
              <div className="work">
                <div className="img"></div>
                <div className="title">{this.props.strings.workuss.pr.text}</div>
                <div className="text">{this.props.strings.workuss.pr.description}</div>
              </div>
              <div className="work">
                <div className="img"></div>
                <div className="title">{this.props.strings.workuss.int.text}</div>
                <div className="text">{this.props.strings.workuss.int.description}</div>
              </div>
              <div className="work">
                <div className="img"></div>
                <div className="title">{this.props.strings.workuss.str.text}</div>
                <div className="text">{this.props.strings.workuss.str.description}</div>
              </div>
              <div className="work">
                <div className="img"></div>
                <div className="title">{this.props.strings.workuss.fwd.text}</div>
                <div className="text">{this.props.strings.workuss.fwd.description}</div>
              </div>
              <div className="work">
                <div className="img"></div>
                <div className="title">{this.props.strings.workuss.off.text}</div>
                <div className="text">{this.props.strings.workuss.off.description}</div>
              </div>
              <div className="work">
                <div className="img"></div>
                <div className="title">{this.props.strings.workuss.tch.text}</div>
                <div className="text">{this.props.strings.workuss.tch.description}</div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Workus;