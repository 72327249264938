import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { match, StaticContext } from 'react-router';
import { createCn } from 'bem-react-classname';
import axios from 'axios';
import Footer from '../components/Footer';
import { SearchVacs } from '../components/vacs/SearchVacs';
import { DepVacs } from '../components/vacs/DepVacs';

interface Vacancies {
    dep: number,
    description_en: string
    id: number
    location: number[]
    occupation: number
    salary_en: string
    salary_ru: string
    title_en: string
    title_ru: string
}

interface MyProps {
    strings: any,
    language: string,
    isVacs?: boolean,
    history?: any,
    location?: any,
    match?: match,
    staticContext?: StaticContext | undefined
};

const cn = createCn('vacancies');

export const Vacancies = React.memo<MyProps>((props) => {
    const { language, strings } = props;
    const [vacancies, setVacs] = useState([]);
    const [searchedVacs, setSearchedVacs] = useState([]);

    const [deps, setDeps] = useState([]);
    const [currentDeps, setCurrentDeps] = useState([]);

    const [depsVacs, setDepsVacs] = useState({});
    const [currentDepVacs, setCurrentDepVacs] = useState({});

    const [showOptions, setShowOptions] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const [lang, setLang] = useState('en');

    let title = 'title_' + lang;

    const onSearch = useCallback(
        (event) => {
            const value = event.target.value;
            if (value.length === 0) {
                setIsSearching(false);
            }
            else {
                setIsSearching(true);
                let tempVacs = [...vacancies].filter((vac: Vacancies) => {
                    return vac[title].toUpperCase().indexOf(value.toUpperCase()) > -1;
                })

                setSearchedVacs(tempVacs);
            }

        }, [vacancies, isSearching, searchedVacs]
    );

    const onDepsClick = useCallback(
        () => {
            setShowOptions(!showOptions);
        }, [showOptions]
    );

    const onDepClick = useCallback(
        (dep: never) => {
            let newDeps;
            if (currentDeps.indexOf(dep) < 0) {
                newDeps = [...currentDeps, dep];
            }
            else {
                newDeps = [...currentDeps];
            }

            newDeps.sort();

            let filteredDepVacs = {};

            for (let i = 0; i < newDeps.length; i++) {
                if (!!depsVacs[newDeps[i]]) {
                    filteredDepVacs[newDeps[i]] = depsVacs[newDeps[i]];
                }
            }

            setCurrentDeps(newDeps);
            setCurrentDepVacs(filteredDepVacs);
            setShowOptions(false);
        }, [depsVacs, currentDeps]
    );

    const onRemoveDepClick = useCallback(
        (dep: never) => {
            let curDeps = [...currentDeps];

            console.log(curDeps, dep);

            const index = curDeps.indexOf(dep);
            curDeps.splice(index, 1);

            curDeps.sort();

            let filteredDepVacs = {};

            for (let i = 0; i < curDeps.length; i++) {
                if (!!depsVacs[curDeps[i]]) {
                    filteredDepVacs[curDeps[i]] = depsVacs[curDeps[i]];
                }
            }

            if (curDeps.length === 0) {
                filteredDepVacs = { ...depsVacs };
            }

            setCurrentDeps(curDeps);
            setCurrentDepVacs(filteredDepVacs);
        }, [depsVacs, currentDeps]
    );

    useEffect(() => {
        if (language === 'grk') {
            setLang('en')
        }
    }, [language]);

    useEffect(() => {
        const url = '/data.json';

        axios.get(url)
        .then((res) => {

            let vacs = res.data;
            setVacs(vacs);
            setSearchedVacs(vacs);
            let depVacs = {};
            vacs.map((vac) => {
                if (vac.hot) {
                    if (!depVacs['hot']) {
                        depVacs['hot'] = [];
                    }
                    depVacs['hot'].push(vac);
                }
                if (!depVacs[strings.deps['cat_' + vac.dep].name]) {
                    depVacs[strings.deps['cat_' + vac.dep].name] = [];
                }
                depVacs[strings.deps['cat_' + vac.dep].name].push(vac);
            });

            let sortedDeps = {};

            Object.keys(depVacs).sort((a) => {
                if (a === 'hot') {
                    return -1;
                }
                return 0;
            }).forEach(function (key) {
                sortedDeps[key] = depVacs[key];
            });

            console.log(sortedDeps);

            setDepsVacs(sortedDeps);
            setCurrentDepVacs(sortedDeps);

            const deps = Object.keys(sortedDeps) as never[];
            setDeps(deps);
        })
        .catch(function (error) {
            console.log(error);
        })
    }, []);

    return (
        <React.Fragment>
            <div id="Vacancies" className={ cn() }>
                <div className="content">
                    <h2>{ strings.jobs }</h2>
                    <div className={ cn('flex-container') }>
                        <div className={ cn('navigation') }>
                            <div className={ cn('dep-select') }>
                                <div className={ cn('deps', { 'is_clicked': showOptions }) } onClick={ onDepsClick }>
                                    { strings.all_deps }
                                </div>
                                <div className={ cn('options', { 'is_visible': showOptions }) }>
                                    { deps && deps.map((dep, index) => {
                                        return (<div key={ dep + index } className={ cn('dep') }
                                                     onClick={ () => {onDepClick(dep)} }>{ dep }</div>);
                                    }) }
                                </div>
                            </div>
                            <div className={ cn('search') }>
                                <input type="text" placeholder={ strings.search } onChange={ onSearch }/>
                            </div>
                        </div>

                        { currentDeps && currentDeps.length > 0 &&
                        (
                            <div className={ cn('current-deps') }>
                                <div className={ cn('current-deps-title') }>
                                    { strings.chosen_deps }
                                </div>
                                <div className={ cn('current-deps-content') }>
                                    { currentDeps.map((dep, index) => {
                                        return (<div key={ dep + index } className={ cn('current-dep') }
                                                     onClick={ () => {onRemoveDepClick(dep)} }>{ dep }</div>);
                                    }) }
                                </div>
                            </div>
                        )
                        }
                        {
                            isSearching ?
                                <SearchVacs filteredVacs={ searchedVacs } language={ lang }
                                            strings={ strings }/> :
                                <DepVacs vacancies={ currentDepVacs } language={ lang }
                                         strings={ strings }/>
                        }

                    </div>
                </div>
            </div>
            <Footer project={ '' } strings={ strings } language={ lang }/>
        </React.Fragment>
    );
});
