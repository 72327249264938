import * as React from 'react';
import { createCn } from 'bem-react-classname';
import { Link } from 'react-router-dom';

interface Vacancies {
    dep: number,
    description_en: string
    id: number
    location: number[]
    occupation: number
    salary_en: string
    salary_ru: string
    title_en: string
    title_ru: string
}


interface MyProps {
    filteredVacs: any,
    language: string,
    strings: any
};

const cn = createCn('vacancies');

export const SearchVacs = React.memo<MyProps>((props) => {
    const {filteredVacs} = props;
    let title = 'title_' + props.language;

    return (
        <React.Fragment>
            { filteredVacs && (filteredVacs.map((item:Vacancies) => {
                let loc;
                let job = item.location.sort();
                let options = [
                    {value: 1, label: props.strings.location[1]},
                    {value: 2, label: props.strings.location[2]},
                    {value: 3, label: props.strings.location[3]},
                ];
                if (Array.isArray(job)) {
                    loc = job.map((el) => {
                        for (let i = 0; i < options.length; i++) {
                            if (el === options[i].value) {
                                return options[i].label;
                            }
                        }
                    }).join(' / ');
                } else {
                    for(let i = 0; i < options.length; i++) {
                        if (options[i].value === job) {
                            loc = options[i].label;
                        }
                    }
                }
                return (
                    <div key={item.id} className={cn("row")}>
                        <div className={cn("title")}><Link key={item.id} className={`job_link`} to={`/job/${item.id}`}>{item[title]}</Link></div>
                        <div className={cn("occupation")}>{props.strings.occupation[item.occupation]}</div>
                    </div>
                );
            })) }
        </React.Fragment>
    );
});
