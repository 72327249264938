import * as React from 'react';
import config from "../../mixins/config";

interface MyProps {
  // project: string
}

interface MyState {
  width: number,
  shift: number,
  active: number,
  touchStart: number
  touchCurrent: number
}

let slideWidth = 1026;
let slidesContainer:any = React.createRef();
let slide:any;

class Slider extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.goLeft = this.goLeft.bind(this);
    this.goRight = this.goRight.bind(this);
    this.goCurrent = this.goCurrent.bind(this);
    this.state = {
      width: 0,
      shift: 0,
      active: 0,
      touchStart: 0,
      touchCurrent: 0
    };
  }

  componentDidMount() {
    let count = config.slides.length;
    slideWidth = slidesContainer.current.offsetWidth;
    this.setState({
      width: slideWidth * count
    });
    slide = setInterval(() => {
      if (this.state.active < count) {
        this.goRight();
      } else {
        this.goCurrent(1);
      }
    }, 3000)
  }

  componentWillUnmount() {
    clearInterval(slide);
  }

  goLeft() {
    let shift;
    let active;
    if (this.state.shift < 0) {
      shift = this.state.shift + slideWidth;
      active = this.state.active - 1;
      this.setState({
        shift: shift,
        active: active
      })
    } else {
      this.goCurrent(config.slides.length - 1)
    }
  }

  goRight() {
    let shift;
    let active;
    if (-this.state.shift < this.state.width - slideWidth) {
      shift = this.state.shift - slideWidth;
      active = this.state.active + 1
    } else {
      shift = 0;
      active = 0;
    }
    this.setState({
      shift: shift,
      active: active
    })
  }

  goCurrent(i:number) {
    let shift = - (i * slideWidth);
    this.setState({
      shift: shift,
      active: i
    })
  }

  handleTouchStart(e) {
    const touch = e.touches[0].clientX;
    this.setState({
      touchStart: touch
    })
  }

  handleTouchMove(e) {
    const touch = e.touches[0].clientX;
    this.setState({
      touchCurrent: touch
    })
  }

  handleFocus(e) {
    clearInterval(slide);
  }

  handleBlur(e) {
    let count = config.slides.length;
    let _self = this;
    slide = setInterval(() => {
      if (_self.state.active < count) {
        _self.goRight();
      } else {
        _self.goCurrent(1);
      }
    }, 3000)
  }

  handleTouchEnd(e) {

    const shift = this.state.touchCurrent - this.state.touchStart;
    if ( shift > 0) {
      this.goLeft();
    } else {
      this.goRight();
    }
    this.setState({
      touchStart: 0,
      touchCurrent: 0
    })
  }

  public render() {
    let bullets:any = [];
    let slides:any = [];
    let slider = config.slides;
    for (let i = 0; i < slider.length; i++) {
      let className = 'bull';
      if (i === this.state.active) {
        className = className + ' active';
      }
      bullets.push(<div key={i} className={className} onClick={() => {this.goCurrent(i)}} />);
      slides.push(<img key={i} src={slider[i]} alt=""/>);
    }
    let style = {
      width: this.state.width,
      marginLeft: this.state.shift
    };
    return (
        <div className="front-slider" onMouseEnter={(e) => this.handleFocus(e)}  onMouseLeave={(e) => this.handleBlur(e)}>
          <div className="controls">
            <div className="left" onClick={this.goLeft}>&nbsp;</div>
            <div className="right" onClick={this.goRight}>&nbsp;</div>
          </div>
          <div className="slides" ref={slidesContainer}>
            <div className="container" style={style} onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)} onTouchEnd={touchEndEvent => this.handleTouchEnd(touchEndEvent)} onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}>
              {slides}
            </div>
          </div>
          <div className="bullets">
            {bullets}
          </div>
        </div>
    );
  }
}

export default Slider;