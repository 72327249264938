import * as React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MyProps {
    id: number,
    title: string,
    timing: string,
    location: string,
    strings: any,
    modal: boolean,
    hideModal: any
}

interface MyState {
    form: any
    show: boolean,
    showTnx: boolean,
    current: any,
    required: boolean,
    modal: boolean,
}

class Modal extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);

        this.state = {
            show: false,
            showTnx: false,
            current: {},
            required: false,
            form: {},
            modal: this.props.modal
        };

        this.sendMail = this.sendMail.bind(this);
    }

    sendMail() {
        const _self = this;
        let data = this.state.form;
        data.job = this.props.title;
        data.id = this.props.id;

        if (!data.contact) {
            _self.setState({
                required: true
            })
        }
        else {
            axios.post('/api/cv', data)
            .then((res) => {
                console.log(res);
                _self.setState({
                    showTnx: true
                })
            })
            .catch(function (error) {
                console.log(error);
                _self.setState({
                    showTnx: false
                })
            })
        }
    }

    setStateFieldValue(e: any, field: string, value: string) {
        if (field === 'contact') {
            this.setState({
                required: false
            })
        }
        let form = { ...this.state.form };
        form[field] = value;
        this.setState({ form });
    }

    createMarkup(string: string) {
        return { __html: string };
    }

    public render() {
        const emailClass = this.state.required ? 'mail modal-field required' : 'mail modal-field';
        const modalClass = this.props.modal ? 'modal active' : 'modal';

        return (
            <div id="Modal" className={ modalClass }>
                <div className="modal-background" onClick={ this.props.hideModal }></div>
                <div className="modal-content">
                    { !this.state.showTnx &&
                    <React.Fragment>
                        <div className="modal-title">
                            { this.props.title }
                        </div>
                        <div className="modal-prefs">
                            { this.props.timing }, { this.props.location }
                        </div>
                        <div className="modal-hide" onClick={ this.props.hideModal }>
                            <FontAwesomeIcon icon='times-circle'/>
                        </div>
                        <div className="modal-row">
                            <div className="name modal-field">
                                <label htmlFor="name">{ this.props.strings.fio }&nbsp;</label>
                                <input type="text" id={ 'name' } name={ 'name' } placeholder={ 'Andrey Popov' }
                                       onChange={ (e) => this.setStateFieldValue(e, 'name', e.target.value) }/>
                            </div>
                            <div className={ emailClass }>
                                <label htmlFor="mail">E-mail:&nbsp;<span
                                    className="required-text">{ this.props.strings.contact_req }</span></label>
                                <input type="email" id={ 'mail' } name={ 'mail' } placeholder={ 'example@mail.com' }
                                       onChange={ (e) => this.setStateFieldValue(e, 'contact', e.target.value) }/>

                            </div>
                        </div>
                        <div className="cv modal-field">
                            <label htmlFor="cv">{ this.props.strings.cv }&nbsp;</label>
                            <input type="text" id={ 'cv' } name={ 'cv' } placeholder={ 'www.simplesite...' }
                                   onChange={ (e) => this.setStateFieldValue(e, 'cv', e.target.value) }/>
                        </div>
                        <div className="name modal-field">
                            <label htmlFor="name">{ this.props.strings.comment }&nbsp;</label>
                            <textarea name="comment" id="comment" placeholder={ 'Something great...' }
                                      onChange={ (e) => this.setStateFieldValue(e, 'comment',
                                          e.target.value) }></textarea>
                        </div>
                        <input type="hidden" value={ this.props.title }/>
                        <div className="modal-footer">
                            <button onClick={ this.sendMail }>{ this.props.strings.send }</button>
                        </div>
                    </React.Fragment>
                    }
                    { this.state.showTnx &&
                    <React.Fragment>
                        <div className="modal-hide" onClick={ this.props.hideModal }>
                            <FontAwesomeIcon icon='times-circle'/>
                        </div>
                        <div className={ 'modal-thx' }>
                            <div className="modal-image">
                                <img src="/img/mail_pig.png" alt=""/>
                            </div>
                            <div className="thx-title"
                                 dangerouslySetInnerHTML={ this.createMarkup(this.props.strings.txh_title) }/>
                            <div className="thx-text"
                                 dangerouslySetInnerHTML={ this.createMarkup(this.props.strings.txh_text) }/>
                            <div className="modal-footer">
                                <button onClick={ this.props.hideModal }>{ this.props.strings.close }</button>
                            </div>
                        </div>
                    </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

export default Modal;
