import * as React from 'react';
import axios from 'axios';
import Footer from '../components/Footer';
import Modal from '../components/Modal';
import MetaTags from 'react-meta-tags';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';

interface MyProps {
    strings: any,
    isVacs: any,
    match: any,
    language: any
}

interface MyState {
    vac: any,
    shareUrl: string,
    modal: boolean,
    lang: string
}

const url = window.location.href;
const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams: any, prop) => searchParams.get(prop)
});
const imgUrl = 'https://rj-games.com/img/logo_big.svg';

class Job extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);

        this.state = {
            vac: {},
            modal: false,
            shareUrl: '',
            lang: 'en'
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.language === 'grk') {
            this.setState({ ...this.state, lang: 'en' })
        }
        if (params.lan === 'ru') {
            this.setState({ ...this.state, lang: 'ru' })
        }
        const url = '/data.json';
        const _self = this;
        return axios.get(url)
        .then((res) => {
            let vacs = res.data;
            vacs.map(function (item) {
                if (item.id === _self.props.match.params.id) {
                    _self.setState({
                        vac: item,
                        shareUrl: 'https://rj-games.com/job/' + item.id
                    })
                }
            });
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    createMarkup(string: string) {
        return { __html: string };
    }

    hideModal() {
        document.body.style.overflowY = 'initial';
        this.setState({
            modal: false
        })
    }

    showModal() {
        document.body.style.overflowY = 'hidden';
        this.setState({
            modal: true
        })
    }

    public render() {
        const vac = this.state.vac;
        console.log(vac);
        let description = 'description_' + this.state.lang;
        let title = 'title_' + this.state.lang;
        let salary = 'salary_' + this.state.lang;
        let salaryString = !!vac[salary] && vac[salary].length > 1 ? <div className="salary">
            <strong>{ this.props.strings.job.salary }:</strong> <span
            dangerouslySetInnerHTML={ this.createMarkup(vac[salary]) }></span>
        </div> : '';
        let loc;
        const options = [
            { value: 1, label: this.props.strings.location[1] },
            { value: 2, label: this.props.strings.location[2] },
            { value: 3, label: this.props.strings.location[3] }
        ];
        let job = vac.location;
        if (Array.isArray(job)) {
            loc = job.map((el) => {
                for (let i = 0; i < options.length; i++) {
                    if (el === options[i].value) {
                        return options[i].label;
                    }
                }
            }).join(' / ');
        }
        else {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === job) {
                    loc = options[i].label;
                }
            }
        }
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{ vac[title] }</title>
                    <meta property="og:title" content={ vac[title] }/>
                    <meta property="og:image" content="/img/logo_big.svg"/>
                    <meta property="og:description" content={ vac[description] }/>
                </MetaTags>
                <div id="Job">
                    <div className="content">
                        <div className="row">
                            <div className="col col-1">
                                <h2>{ vac[title] }</h2>
                                { salaryString }
                                <div className="schedule">
                                    <strong>{ this.props.strings.job.schedule }:</strong>&nbsp;
                                    <span dangerouslySetInnerHTML={ this.createMarkup(
                                        this.props.strings.occupation[vac.occupation]) }/>
                                </div>
                                <div className="location">
                                    <strong>{ this.props.strings.job.location }:</strong>&nbsp;<span>{ loc }</span>
                                </div>
                                <div className="description">
                                    <div dangerouslySetInnerHTML={ this.createMarkup(vac[description]) }></div>
                                </div>
                            </div>
                            <div className="col col-2">
                                <div className="mailto">
                                    <div className={ 'maillink' }>
                                        <span className={ 'mail-link' }
                                              onClick={ this.showModal }>{ this.props.strings.apply }</span>
                                    </div>
                                    <div className={ 'recinfo' }>
                                        <div className="rectitle">{ this.props.strings.share_job }</div>
                                        <div className="recsoc">
                                            <div className="share">
                                                {/*
                                                //@ts-ignore */ }
                                                <FacebookShareButton
                                                    url={ url }
                                                    quote={ vac[title] }
                                                ><img
                                                    src="/img/rec/fb_rec.svg" alt=""/>
                                                </FacebookShareButton>
                                            </div>
                                            <div className="share">
                                                {/*
                                                //@ts-ignore */ }
                                                <LinkedinShareButton
                                                    url={ url }
                                                    title={ vac[title] }
                                                ><img
                                                    src="/img/rec/in_rec.svg" alt=""/>
                                                </LinkedinShareButton>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <Modal hideModal={ this.hideModal } id={ vac.id } modal={ this.state.modal }
                               title={ vac[title] } timing={ this.props.strings.occupation[vac.occupation] }
                               strings={ this.props.strings } location={ this.props.strings.location[vac.location] }/>
                    </div>
                </div>
                <Footer project={ '' } strings={ this.props.strings } language={ this.state.lang }/>
            </React.Fragment>
        );
    }
}

export default Job;
