export default {
  workus: 'Δουλέψτε μαζί μας',
  apply: 'Εφαρμογή',
  refer: 'Σύσταση φίλου',
  send: 'Αποστολή',
  close: 'Κλείσιμο',
  txh_title: 'Σας ευχαριστούμε για το ενδιαφέρον σας στην RJ Games!',
  txh_text: 'Θα εξετάσουμε το βιογραφικό σας και θα σας<br/>απαντήσουμε εντός τριών εργάσιμων ημερών.',
  fio: 'Όνομα',
  cv: 'Σύνδεσμος βιογραφικού/πορτφόλιο',
  contact: 'Στοιχεία επικοινωνίας',
  contact_req: 'Απαιτείται διεύθυνση email',
  comment: 'Σχόλια',
  workuss: {
    pr: {
      text: 'Έργα παγκόσμιας κλάσης',
      description: 'Δημιουργούμε παιχνίδια παγκόσμιας κλάσης, που στέκονται αντάξια δίπλα σε κυρίαρχα της διεθνούς αγοράς και μαγεύουν εκατομμύρια χρήστες σε όλο τον κόσμο.'
    },
    int: {
      text: 'Εταιρεία διεθνούς εμβέλειας',
      description: 'Εργαστείτε από τα κεντρικά μας στην Κύπρο η εξ αποστάσεως από οποιοδήποτε μέρος του κόσμου.'
    },
    str: {
      text: 'Δυνατή και ενωμένη ομάδα',
      description: 'Η RJ Games είναι μια δεμένη ομάδα επαγγελματιών και παθιασμένων γκέιμερ. Ο αμοιβαίος σεβασμός, η φιλικότητα και η εμπιστοσύνη αποτελούν βασικά συστατικά του DNA μας.'
    },
    fwd: {
      text: 'Διαρκής εξέλιξη!',
      description: 'Η ενασχόληση με πολύπλοκα έργα μάς κάνει πιο δυνατούς και πιο έμπειρους. Κάθε νέο έργο αποτελεί πρόκληση για την ομάδα μας και πάντα ανεβάζουμε τον πήχη στην ποιότητα των παιχνιδιών μας.'
    },
    off: {
      text: 'Ελκυστικές εγκαταστάσεις',
      description: 'Οι ευχάριστες εγκαταστάσεις μας περιλαμβάνουν κουζίνες, βιβλιοθήκες, κονσόλες βιντεοπαιχνιδιών και τραπέζια πινγκ πονγκ, καθώς και ευρύχωρα γραφεία και ισχυρούς υπολογιστές.'
    },
    tch: {
      text: 'Ενδοεταιρική εκπαίδευση',
      description: 'Εκτιμάμε τη δεκτικότητα σε νέα ερεθίσματα και τις φρέσκες ιδέες, για αυτό και οργανώνουμε τακτικά ομιλίες και προσκαλούμε ειδικούς από άλλες εταιρείες προκειμένου να μοιραστούν την εμπειρία τους μαζί μας.'
    }
  },
  workus_email: 'Μήπως θέλετε να εργαστείτε μαζί μας και δεν βλέπετε κάποια θέση που να σας ταιριάζει;<br>Στείλτε μας το βιογραφικό και τη συνοδευτική επιστολή σας εδώ:',
  projects: 'Έργα',
  projects_list: {
    puzzlebreakers: {
      description: '<p>Παίξτε παιχνίδια τριπλής αντιστοίχισης (match-3) για να ελέγξετε τους χαρακτήρες σας και να επιτεθείτε στους εχθρούς σας στο πεδίο μάχης. Στο πρωτοποριακό Puzzle RPG που αλλάζει τα δεδομένα στο είδος: το Puzzle Breakers! Οι εχθροί σας μετακινούνται στο πεδίο και επιστρατεύουν ύπουλες τακτικές για να σας κερδίσουν! Δυνατοί πολεμιστές προσπαθούν να σας κοντράρουν σε μάχες σώμα με σώμα, ενώ τοξοβόλοι και μάγοι επιτίθενται από τις κορυφές πύργων και από κρυψώνες. Χρησιμοποιήστε τους ήρωές σας και τις μοναδικές σας ικανότητες για να επιτεθείτε και να ελέγξετε τους εχθρούς σας ή να θεραπεύσετε τους συμμάχους σας. Διαλύστε τείχη και προπετάσματα, ρίξτε βολές σε εκρηκτικά βαρέλια και διαγράψτε τους ενοχλητικούς εχθρούς σας από τον χάρτη μέσα σε μια έκρηξη οργής!</p><p>Είδος: Puzzle RPG<br>Σκηνικό πλαίσιο: Φαντασία</p>',
      features: '<ul>\n' +
          '\t<li>Απλό, στρωτό παιχνίδι με ασυνήθιστο βάθος για το είδος του.</li>\n' +
          '\t<li>Σωρεία συναρπαστικών επιπέδων και πολλοί διαφορετικοί τρόποι ολοκλήρωσής τους.</li>\n' +
          '\t<li>Μοναδικοί εχθροί με δικές τους ικανότητες και στρατηγικές.</li>\n' +
          '\t<li>Καθηλωτικές μάχες παίκτη εναντίον παίκτη (PvP) σε ένα ξεχωριστό τακτικό πεδίο μάχης.</li>\n' +
          '\t<li>Συνεργατικοί τύποι παιχνιδιού, όπου μπορείτε να παίξετε και να συνομιλήσετε με παίκτες από όλο τον κόσμο.</li>\n' +
          '\t<li>Μάχες σε επίπεδο φατριών, όπου μπορείτε να βιώσετε την εμπειρία της δράσης PvP σε μεγάλη κλίμακα.</li>\n' +
          '\t<li>Πάνω από 100 μοναδικοί ήρωες, που σας προσφέρουν αμέτρητους τρόπους δημιουργίας των ονειρικών σας ομάδων.</li>\n' +
          '\t<li>Ένα σύστημα σχετικά με την εξέλιξη στα επιπέδων των ηρώων, βασισμένο στη λεπτομέρεια και σε δομή διακλαδώσεων.</li>\n' +
          '</ul>\n'
    },
    imperial: {
      description: '<p>Τεράστιες ένοπλες συρράξεις και ανθρωπογενείς καταστροφές έχουν οδηγήσει στην κατάρρευση της παγκόσμιας τάξης. Παραστρατιωτικές φατρίες μάχονται για τον έλεγχο των πόρων. Η πατρίδα σας πνίγεται στις φλόγες. Ενώστε τις δυνάμεις σας με φίλους για να την προστατέψετε και βοηθήστε τη να αναδειχθεί πάνω από τα άλλα κράτη σε ένα κυριολεκτικά παγκόσμιο παιχνίδι στρατηγικής!</p><p>Είδος: Παγκόσμια στρατηγική<br>Σκηνικό πλαίσιο: Το εγγύς μέλλον</p>',
      features: '<ul><li>Σφοδρές μάχες στον πραγματικό παγκόσμιο χάρτη με εκατοντάδες χιλιάδες παίκτες.</li><li>Υψηλής ποιότητας γραφικά που ξεγελούν.</li><li>Πόλεμοι φατριών για πόρους και ζωτικό χώρο, αλλά και πολιτικά, διπλωματικά και κατασκοπευτικά παιχνίδια.</li><li>Εκατομμύρια παίκτες ενώνουν τις δυνάμεις τους σε αυτόνομες υποομάδες. Δεινές μάχες για τη Μόσχα, το Παρίσι, το Λονδίνο, τη Νέα Υόρκη και εκατοντάδες άλλες μεγαλουπόλεις του κόσμου.</li><li>Ευρεία ποικιλία γεγονότων εντός παιχνιδιού, με μοναδικές επιβραβεύσεις, και εξερεύνηση χαρτών πλάι στους συμπολεμιστές σας.</li><li>Η ιστορία του κόσμου στο παιχνίδι δημιουργείται από τους παίκτες επιτόπου.</li></ul>'
    },
    dakota: {
      description: '<p>Χάρη στα ψηλά της βουνά, τα παρθένα της ποτάμια και τα απάτητα δάση της, η Ντακότα είναι αδιαμφισβήτητα ιδανικό μέρος για να δημιουργήσετε τη φάρμα σας. Η ιδιαίτερη γοητεία της Βόρειας Αμερικής του 19ου αιώνα, οι απεριόριστες ευκαιρίες διαχείρισης και καλλιέργειας της φάρμας σας, οι δεκάδες χαρακτήρες και τα μοναδικά κτίρια καθιστούν αυτό το παιχνίδι πραγματική απόλαυση για τους αριστοτέχνες των παιχνιδιών τύπου φάρμας.</p><p><br>Είδος: Φάρμα<br>Σκηνικό πλαίσιο: Βόρεια Αμερική του 19ου αιώνα</p>',
      features: '<ul><li>Πάνω από 15 αχανείς εκτάσεις.</li><li>Επιλογή μετάβασης στον εσωτερικό κόσμο του σπιτιού σας και δυνατότητα διακόσμησης του κτήματος μέσα και έξω.</li><li>Πάνω από 100 κτίρια και αντικείμενα περιβάλλοντος.</li><li>Περισσότερα από 15 διαφορετικά είδη ζώων, με τις δικές τους μοναδικές συμπεριφορές.</li><li>Εκτεταμένο σύστημα παραγωγής.</li><li>Επιλογή αλλαγής του τοπίου.</li><li>Εκατοντάδες φυσικά χαρακτηριστικά, που κάνουν κάθε σκηνικό μοναδικό.</li></ul>'
    },
    undercontrol: {
      description: '<p>Ένας παγκόσμιος πόλεμος εγκαθίδρυσε μια νέα τάξη πραγμάτων, και πλέον ο κόσμος εξουσιάζεται από την πλευρά με τη μεγαλύτερη στρατιωτική ισχύ. Ηγηθείτε μιας στρατιωτικής βάσης, δημιουργώντας παράλληλα ένα ισχυρό στράτευμα και βρίσκοντας ομοϊδεάτες συμμάχους προκειμένου να σχεδιάσετε εκ νέου τον χάρτη του μελλοντικού κόσμου.</p><p>Είδος: Παγκόσμια στρατηγική<br>Σκηνικό πλαίσιο: Στρατιωτικές επιχειρήσεις</p>',
      features: '<ul><li>Στρατηγική παίκτη εναντίον παίκτη σε πραγματικό χρόνο.</li><li>Τεράστια συλλογή μονάδων: πεζικό, βαρέα οχήματα μάχης, πυροβολικό, πολεμική αεροπορία.</li><li>Μη γραμμικό σύστημα αναβάθμισης μονάδων.</li><li>Διάφοροι τύποι μάχης παίκτη εναντίον παίκτη – από 2 έως 6 παίκτες σε μία μάχη.</li><li>Ιδανικά σχεδιασμένη ισορροπία και ευφυής μηχανισμός επιλογής αντιπάλων.</li><li>Φατρίες και μάχες περιοχής.</li><li>Μηνιαία γεγονότα εντός παιχνιδιού με μοναδικές επιβραβεύσεις.</li></ul>'
    },
    dungeonbrawlers: {
      description: '<p>Πριν πολύ καιρό, τότε που βασιλιάδες εξουσίαζαν τον κόσμο και όμορφοι ιππότες μάχονταν για τις καρδιές των ευγενών γυναικών, οι αληθινοί ήρωες εξορμούσαν στα μπουντρούμια, αναζητώντας το χρυσάφι και τη δόξα. Ενώστε τις δυνάμεις αυτών των ηρώων υπό τα λάβαρά σας και αναχωρήστε για την πιο επική και συναρπαστική περιπέτεια του κόσμου.</p><p>Είδος: Στρατηγική</p><p>Σκηνικό πλαίσιο: Φαντασία</p>',
      features: '<ul><li>5 κατηγορίες χαρακτήρων: επικίνδυνοι πολεμιστές, επιδέξιοι κυνηγοί, πανίσχυροι μάγοι, ρωμαλέοι υπερασπιστές και εφευρετικοί μηχανικοί.</li><li>Πάνω από 15 μοναδικοί χαρακτήρες.</li><li>Περισσότεροι από 100 τύποι εχθρών, από υπερμεγέθεις αρουραίους και ατμορομπότ μέχρι και μεγαλομανείς αλχημιστές.</li><li>Μάχες εναντίον άλλων παικτών, κατατάξεις ημέρας και εβδομαδιαίοι διαγωνισμοί.</li><li>Μάχες και ανάπτυξη φατριών.</li><li>90 επιτεύγματα και δεκάδες τίτλοι.</li><li>Πάνω από 150 επίπεδα ιστορίας σε διάφορα μέρη του βασιλείου.</li></ul>'
    },
    jackandjill: {
      description: '<p>Το Jack and Jill είναι η μαγευτική ιστορία των περιπετειών μικρών ανθρώπων. Βοήθησε τους ιδιόμορφους κατοίκους της Puppetville να καλλιεργήσουν, στόλισε την πόλη και βοήθησε τον γενναίο Τζακ να βρει την όμορφή του Τζιλ.</p><p>Σκηνικό πλαίσιο: Παραμύθι</p>',
      features: '<ul><li>Πάνω από 20 χαρακτήρες με τις δικές τους ιστορίες.</li><li>Περισσότερες από 120 συναρπαστικές αποστολές.</li><li>Ένας πλήρως διαδραστικός κόσμος, στον οποίο μπορείτε να αλληλεπιδράσετε με οτιδήποτε βλέπετε.</li><li>Πάνω από 50 κτίρια και αντικείμενα περιβάλλοντος.</li><li>Περισσότερα από 100 στοιχεία τοπίου.</li><li>Ολοκληρωμένο, στρωτό σύστημα χειροτεχνιών και κατασκευών.</li><li>Επιλογή αλλαγής της όψης των χαρακτήρων και δεκάδες αμφιέσεις για όλα τα γούστα.</li></ul>'
    },
    underfire: {
      description: '<p>Με την άφιξή της σε απομακρυσμένους κόσμους, η ανθρωπότητα αντιμετώπισε καταστάσεις που την άλλαξαν ριζικά. Τα φρικτά έντομα του Σμήνους, η ακμή της τεχνητής νοημοσύνης, οι μεταλλαγμένοι μα και η απληστία του ίδιου του ανθρώπινου γένους τού έθεσαν μια πρόκληση. Μπείτε στη Συνομοσπονδία των Ανεξάρτητων Κόσμων, χτίστε τη δική σας αστρική βάση και προσχωρήστε στον πόλεμο για το μέλλον ολόκληρης της ανθρωπότητας.</p><p>Σκηνικό πλαίσιο: Επιστημονική φαντασία</p>',
      features: '<ul><li>Μια συναρπαστική ιστορία 6 επεισοδίων πλοκής και περισσότερων από 120 αποστολών.</li><li>Πάνω από 100 μοναδικοί στρατιώτες και αντίπαλοι, με ατομικές συμπεριφορές και ειδικές δεξιότητες.</li><li>Δικός σας διαστημικός στόλος και μάχες μεγάλης κλίμακας για διαστημικές αποικίες.</li><li>Προηγμένο σύστημα αναβάθμισης μονάδων και ηρώων, με πάνω από 150 ειδικότητες.</li><li>Πολλαπλοί τύποι παιχνιδιού παίκτη εναντίον παίκτη.</li><li>Ένα καλά αναπτυγμένο σύστημα πολεοδομίας, με πάνω από 50 κτίρια στην αποικία και εντός τροχιάς.</li><li>Δυναμικές και θεαματικές μάχες, όπου η κάθε σας απόφαση μπορεί να ανατρέψει την κατάσταση και να σας φέρει τη νίκη.</li></ul>'
    },
    undersiege: {
      description: '<p>Το Under Siege είναι η δική μας εκδοχή για το είδος της υπεράσπισης πύργων. Κυβερνήστε το βασίλειο και αποκρούστε δεκάδες βίαιες πολιορκίες με τους καλύτερους πολεμιστές και διάσημους ήρωες να βρίσκονται στις υπηρεσίες σας. Χρησιμοποιήστε παγίδες, στρατηγικά κόλπα και πανίσχυρη μαγεία. Υπερασπιστείτε το δικαίωμά σας στο στέμμα!</p><p>Είδος: Υπεράσπιση πύργων</p>',
      features: '<ul><li>Υπεράσπιση πύργων σε ένα σκηνικό κλασικής φαντασίας.</li><li>Δεκάδες μοναδικές παγίδες και ξεχωριστοί πολεμιστές, ισχυρή μαγεία και γενναίοι ήρωες θα σας βοηθήσουν να οικοδομήσετε μια ανυπέρβλητη άμυνα.</li><li>Πάνω από 100 ξεχωριστοί εχθροί, από κακά ξωτικά και τρελούς γνώμους μέχρι και δαίμονες, ζόμπι και ροζ ελέφαντες.</li><li>Φωτιές, εκρήξεις, κεραυνοί και τα δεινοπαθήματα των εχθρών σας – εκατοντάδες πολύχρωμα εφέ, και όχι για τους λιπόψυχους.</li><li>4 βασικά σενάρια και πάνω από 50 αποστολές με ποικίλα επίπεδα δυσκολίας και τοπία, από τροπικά νησιά μέχρι και μπουντρούμια γεμάτα θησαυρούς.</li><li>Τουρνουά και διαγωνισμοί εναντίον παικτών από όλο τον κόσμο.</li><li>Πάνω από 10 τύποι παιχνιδιού, που θα σας συναρπάσουν!</li></ul>'
    },
  },
  play_on: 'ΠΑΙΞΤΕ ΣΤΟ:',
  techs: 'Τεχνολογίες:',
  features: 'ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ ΠΑΙΧΝΙΔΙΟΥ',
  company: 'ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ',
  facts: {
    years: 'ΕΤΗ<br/>' +
        ' ΣΤΟΝ ΤΟΜΕΑ',
    projects: 'ΜΕΓΑΛΑ<br/>' +
        ' ΕΡΓΑ',
    employees: 'ΕΡΓΑΖΟΜΕΝΟΙ<br/>' +
        ' ΣΤΗΝ ΕΤΑΙΡΕΙΑ'
  },
  jobs: 'Εργασίες',
  slogan: 'μέσα από τα αστέρια<br/> γεννιούνται τα καλύτερα παιχνίδια!',
  occupation: [
    '',
    'Πλήρης απασχόληση',
    'Μερική απασχόληση',
    'Yπεργολαβία'
  ],
  location: [
    '',
    'Μόσχα',
    'Λεμεσός',
    'Εξ αποστάσεως'
  ],
  deps: {
    "cat_1": {
      "name": "μάρκετινγκ",
      "description": "<p><strong>Πιστεύουμε ότι ένα καλό παιχνίδι είναι μόνο ένα μέρος της επιτυχίας. Η απόκτηση πελατών και η κοινότητα μπορούν να μετατρέψουν ένα καλό παιχνίδι σε επικό.</strong></p><ul><li>Εργάζεστε σε κάθε δίαυλο απόκτησης πελατών.</li><li>Είμαστε ανοιχτοί σε νέες δημιουργικές μεθόδους μάρκετινγκ και απόκτησης πελατών.</li><li>Αναζητούμε διαρκώς νέους τρόπους απόκτησης πελατών και ενίσχυσης της διαδραστικότητας.</li><li>Διατηρούμε τη φιλικότητα της κοινότητάς μας και ακούμε τους παίκτες μας.</li></ul>"
    },
    "cat_2": {
      "name": "σχεδιασμός παιχνιδιών",
      "description": "<p><strong>Όσον αφορά τις διαδικασίες μας στον σχεδιασμό των παιχνιδιών, μας καθοδηγεί μια συστηματική προσέγγιση. Μελετάμε στενά κάθε ιδέα, επιλέγουμε τις καλύτερες και τις χρησιμοποιούμε ως θεμέλιο, προκειμένου να δημιουργήσουμε ενδιαφέροντα παιχνίδια με λεπτομερή μηχανική.</strong></p><ul><li>Δημιουργικές και αντισυμβατικές εργασίες που αφορούν τη λογική του παιχνιδιού, τις οικονομικές εκφάνσεις και τα στοιχεία ανάλυσης δεδομένων, τα οποία δεν θα σας αφήσουν στιγμή να βαρεθείτε.</li><li>Μια καλά σχεδιασμένη δομή επικοινωνιακού διαύλου και μια σωστή ισορροπία μεταξύ δημιουργικότητας και χρήσης των διαθέσιμων πρακτικών.</li><li>Δυνατότητα άσκησης επιρροής στο προϊόν: αποκρισιμότητα από το τμήμα διαχείρισης και παραγωγοί που είναι πάντοτε έτοιμοι να δώσουν σημασία σε ενδιαφέρουσες, τεκμηριωμένες ιδέες.</li></ul>"
    },
    "cat_3": {
      "name": "τέχνη",
      "description": "<p><strong>Οι καλλιτέχνες της RJ Games είναι πραγματικοί επαγγελματίες, που δημιουργούν κόσμους και αξέχαστους χαρακτήρες, ωθώντας ο ένας τον άλλον σε περισσότερα επιτεύγματα.</strong></p><ul><li>Είτε πρόκειται για φαντασία, είτε για στρατιωτικές επιχειρήσεις, είτε για απλό παιχνίδι, οι καλλιτέχνες μπορούν να επιλέξουν τα έργα στα οποία θα δουλέψουν, προκειμένου αυτά να ταιριάζουν στην ξεχωριστή τους τεχνοτροπία.</li><li>Ευκαιρία για μελέτη νέων πτυχών στα γραφικά και για εργασία σε ενδιαφέροντα καθήκοντα στους τομείς της εννοιακής τέχνης, της μοντελοποίησης, της εμψύχωσης και της διεπαφής χρήστη.</li><li>Αμοιβαία υποστήριξη και υγιής ανταγωνισμός σε μια ομάδα από αυθεντίες στην τέχνη τους, για ένα αποτέλεσμα που θα σας κάνει περήφανους.</li></ul>"
    },
    "cat_4": {
      "name": "ανάπτυξη",
      "description": "<p><strong>Οι προγραμματιστές μας διαχειρίζονται πολύπλοκες εργασίες και επιτυγχάνουν τους σκοπούς τους, μαγεύοντας εκατομμύρια παίκτες από όλο τον κόσμο με τα παιχνίδια της εταιρείας μας.</strong></p><ul><li>Οι προγραμματιστές επιλέγουν τις δικές τους μεθόδους επίλυσης προβλημάτων, ακολουθώντας τη μία και βασική αρχή που λέει ότι πίσω από κάθε γραμμή κώδικα πρέπει να υπάρχει ένα πλήρως αναπτυγμένο σκεπτικό.</li><li>Ανταλλαγή εμπειριών, από κοινού μελέτη νέων τεχνολογιών και διατήρηση ανοιχτής στάσης στην υιοθέτηση φρέσκων αντιλήψεων, σε μια ατμόσφαιρα επαγγελματικής εμπιστοσύνης.</li><li>Οι ειδικοί μας δεν μετατρέπουν το κομμάτι της ανάπτυξης σε δόγμα, αλλά συνάμα δεν παίρνουν τη δουλειά τους ελαφρά τη καρδία – επιλέγουν τα σωστά εργαλεία και δημιουργούν αξιόπιστα προϊόντα.</li></ul>"
    },
    "cat_5": {
      "name": "διαχείριση",
      "description": "<p><strong>Απολαμβάνουμε να δημιουργούμε μια ατμόσφαιρα βολικότητας και ευχαρίστησης στις εγκαταστάσεις μας, προκειμένου να διασφαλίζουμε ότι κάθε μέλος της ομάδας μας αισθάνεται άνετα ανάμεσα στους συναδέλφους του, τόσο κατά την εργασία όσο και κατά τη χαλάρωση.</strong></p><ul><li>Ευέλικτη προσέγγιση στη ροή των εργασιών – επικεντρωνόμαστε στα αποτελέσματα και όχι στις διαδικασίες για τις ίδιες τις διαδικασίες.</li><li>Μια ομάδα δεμένη, όπου πάντα υποστηρίζουμε ο ένας τον άλλον – ποτέ δεν θα μας ακούσετε να λέμε «δικό σου είναι το πρόβλημα».</li><li>Απλότητα και διαφάνεια – αγωνιζόμαστε ώστε να κάνουμε κάθε πολύπλοκη διαδικασία απλή, κατανοητή και προβλέψιμη για τους εργαζόμενούς μας.</li></ul>"
    }
  },
  contacts: {
    title: 'Επικοινωνία',
    cy: {
      location: 'Λεμεσός, Κύπρος',
      address: 'RJ Games LTD, HE 355950, Αγίου Νεοφύτου 3, 3036'
    },
    ru: {
      location: 'Μόσχα, Ρωσία',
      address: 'Μπαλσάγια Τατάρσκαγια Ούλιτσα, Λεωφ. 35, 7-9'
    },
  },
  terms: 'Όροι χρήσης',
  privacy: 'Πολιτική απορρήτου',
  social: 'Ακολουθήστε μας στα κοινωνικά δίκτυα:',
  social_project: 'Κοινότητα:',
  support: 'Υποστήριξη',
  copyright: 'RJ Games LTD©️, '+ new Date().getFullYear(),
  next_game: 'Επόμενο παιχνίδι',
  search: 'ΑΝΑΖΗΤΗΣΗ…',
  chosen_deps: 'Επιλεγμένα τμήματα:',
  all_deps: 'Όλα τα τμήματα',
  become_team: 'Εργαστείτε στην RJ Games!',
  become_jobs: 'Τελευταίες εργασιακές ευκαιρίες',
  share_job: 'Κοινοποίηση εργασίας:',
  referral_program: 'Πρόγραμμα συστάσεων',
  ref_desc: 'Η RJ Games είναι μια δεμένη ομάδα επαγγελματιών και παθιασμένων γκέιμερ. Οι βασικές αξίες της κουλτούρας μας είναι η αγάπη για τη δουλειά μας, ο αμοιβαίος σεβασμός και η εμπιστοσύνη.<br/><br/>Πολλοί από τους εργαζόμενούς μας μπαίνουν στην ομάδα μας ως συστημένα πρόσωπα (τόσο από εξωτερικές όσο και από εσωτερικές πηγές). Συστήνοντας επαγγελματίες σε εμάς, αφενός, τους βοηθάτε να μπουν σε μια ομάδα ανθρώπων με κοινή νοοτροπία και, αφετέρου, αποκτάτε ένα ευχάριστο χρηματικό μπόνους!',
  ref_reward: 'Η επιβράβευση για μια σύσταση',
  ref_rub_any: 'για κάθε θέση',
  ref_rub_special: 'για μια επείγουσα κενή θέση<br/>που επισημαίνεται με',
  ref_bonus: 'Μπόνους πληρωμή',
  ref_bonus_text: '<li>Η σύσταση γίνεται αποδεκτή υπό την προϋπόθεση ότι δεν έχουμε έρθει ποτέ προηγουμένως σε επαφή με τον/την συστηνόμενο/η υποψήφιο/α και ότι το εν λόγω άτομο δεν έχει περάσει ποτέ συνέντευξη από εμάς.</li><li>Μια αποδεκτή σύσταση είναι έγκυρη για ένα έτος από τη στιγμή της συμπλήρωσης της ειδικής φόρμας.</li><li>Για τη σύσταση ενός σχετικού επαγγελματία καταβάλλεται μπόνους στο 100% του ποσού έπειτα από επιτυχή ολοκλήρωση της δοκιμαστικής περιόδου.</li>',
  ref_howto: 'Πώς συμμετέχω στο πρόγραμμα;',
  ref_howto_text: 'Εάν θέλετε να προτείνετε έναν επαγγελματία, συμπληρώστε τη <a target="_blank" href="https://forms.gle/WX8vZTTjJApHn6jq9">φόρμα</a>. Εάν έχετε αμφιβολίες σχετικά με το ποια θέση ταιριάζει στο άτομο που μας συστήνετε, απλώς αφήστε το πεδίο κενό, και εμείς θα βρούμε μια καλή επιλογή.<br/><br/>Ανοιχτές θέσεις εργασίας: ο κατάλογος με τις τρέχουσες θέσεις εργασίας βρίσκεται στην ενότητα <a href="/vacs">Εργασίες</a>.<br/><br/>Στείλτε τα ερωτήματά σας σχετικά με το πρόγραμμα μπόνους στην ηλεκτρονική διεύθυνση <a href="mailto:job@rj-games.com">job@rj-games.com</a>, και θα χαρούμε να σας απαντήσουμε σε όλα.',
  ref_vacs: 'Εργασίες',
  job: {
    salary: 'Μισθός',
    schedule: 'Πρόγραμμα',
    location: 'Τοποθεσία'
  },
  available: 'Παίξτε τώρα:',
  available2: 'Παίξτε τώρα!',
  more: 'Μάθετε περισσότερα για το παιχνίδι',
  play_in: 'παίξτε Puzzle Breakers:',
  closed: 'Αυτό το παιχνίδι έχει ήδη κλείσει, ωστόσο θα παραμείνει για πάντα σημαντικό μέρος της ιστορίας μας.<br>Για νέες' +
      ' περιπέτειες δείτε το <b><a href="https://play.google.com/store/apps/details?id=com.playrix.puzzlebreakers" target="_blank">Puzzle Breakers!</a></b>'
};
