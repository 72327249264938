import React from 'react';

interface MyProps {
    to: any,
    children: any
}

class ExternalLink extends React.Component<MyProps> {
    render() {
        return (<a href={this.props.to} target="_blank">{this.props.children}</a>);
    }
}

export default ExternalLink;
