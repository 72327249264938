export default {
  workus: 'Работа у нас',
  apply: 'Откликнуться',
  refer: 'Порекомендовать друга',
  send: 'Отправить',
  close: 'Закрыть',
  txh_title: 'Спасибо за интерес к RJ Games!',
  txh_text: 'Мы получили ваше резюме и свяжемся с вами<br/>в течение 3-х рабочих дней :)',
  fio: 'Имя',
  cv: 'Ссылка на Резюме/Портфолио',
  contact: 'Контактная информация',
  contact_req: 'E-mail обязательно поле',
  comment: 'Комментарии',
  workuss: {
    pr: {
      text: 'Проекты мирового уровня',
      description: 'Мы создаём первоклассные игры, которые конкурируют с лидерами международного рынка и радуют миллионы пользователей по всему миру.'
    },
    int: {
      text: 'Международная компания',
      description: 'Работай из головного офиса на Кипре, из офиса в Москве или удалённо из любой части света.'
    },
    str: {
      text: 'Сильная и дружная команда',
      description: 'RJ Games — это сплоченная команда профессионалов и заядлых геймеров. Взаимное уважение, дружелюбие и доверие — ключевые элементы нашей ДНК.'
    },
    fwd: {
      text: 'Движемся только вперёд!',
      description: 'Решение сложных задач делает нас сильнее и опытнее. Каждый новый проект бросает команде свой вызов, и мы раз за разом поднимаем планку качества наших игр.'
    },
    off: {
      text: 'Крутые офисы',
      description: 'В наших уютных офисах есть кухня, библиотека, приставки, пинг-понг, а также большие столы и мощные компьютеры.'
    },
    tch: {
      text: 'Обучение внутри компании',
      description: 'Мы ценим открытость и свежие идеи, поэтому регулярно проводим лекции и приглашаем в офис экспертов других компаний для обмена опытом.'
    }
  },
  workus_email: 'Хочешь работать с нами, но подходящей вакансии не нашлось?<br>Присылай резюме и рассказ о себе на наш адрес:',
  projects: 'Проекты',
  projects_list: {
    puzzlebreakers: {
      description: '<p>Составляйте крутые комбо из рун, собирайте героев и сражайтесь с врагами! Puzzle Breakers — невероятная игра-головоломка в жанре "три в ряд" с элементами РПГ. Противники перемещаются по полю, используя коварные приемы. Могучие воины пытаются сокрушить вас в ближнем бою, лучники и маги атакуют из засад и башен. Используйте уникальные умения своих героев, чтобы контролировать поле боя, наносить урон врагам и исцелять союзников. Разрушайте стены и укрытия и взрывайте бочки с порохом — враги содрогнутся от вашей ярости!</p><p>Жанр: Puzzle RPG<br>Сеттинг: Фэнтэзи</p>',
      features: '<ul>\n' +
          '\t<li>Простой и понятный геймплей с исключительной для жанра глубиной.</li>\n' +
          '\t<li>Огромное количество интереснейших уровней с большим разнообразием тактик прохождения.</li>\n' +
          '\t<li>Необычные враги с различными способностями и поведением.</li>\n' +
          '\t<li>Захватывающие PvP-сражения на уникальном тактическом поле боя.</li>\n' +
          '\t<li>Игровые режимы с возможностью кооперации и общения с игроками со всего мира.</li>\n' +
          '\t<li>Возможность участия в клановых войнах — масштабных и впечатляющих PvP-баталиях.</li>\n' +
          '\t<li>Более ста уникальных героев, открывающих бесконечные возможности для создания собственных вариантов команд.</li>\n' +
          '\t<li>Глубокая и разветвленная система развития героев.</li>\n' +
          '</ul>\n'
    },
    imperial: {
      description: '<p>В результате крупных вооруженных конфликтов и техногенных катастроф мировой порядок рухнул. Военизированные фракции борются за контроль над ресурсами. Твоя родина в огне сражений. Объединись с друзьями, чтобы защитить ее и возвысить над остальными государствами в по-настоящему глобальной стратегии!</p><p>Жанр: Глобальная стратегия<br>Сеттинг: Ближайшее будущее</p>',
      features: '<ul><li>Масштабные сражения на реальной карте мира с участием сотен тысяч игроков.</li><li>Реалистичная высококачественная графика.</li><li>Войны кланов за ресурсы и территорию, политика, дипломатия, шпионские игры.</li><li>Объединение миллионов игроков во фракции. Ожесточенные битвы за Москву, Париж, Лондон, Нью-Йорк и сотни других крупнейших городов мира.</li><li>Разнообразные игровые события с уникальными наградами, исследование карты вместе с товарищами по оружию.</li><li>История игрового мира создается игроками здесь и сейчас.</li></ul>'
    },
    dakota: {
      description: '<p>Высокие горы, чистые реки, нетронутые леса – Дакота, пожалуй, лучшее место, чтобы начать свое хозяйство. Удивительное очарование Северной Америки XIX века, огромные возможности по управлению своим поместьем, десятки персонажей и уникальных построек станут настоящим подарком для ценителей жанра ферма.</p><p><br>Жанр: Ферма<br>Сеттинг: Северная Америка XIX века</p>',
      features: '<ul><li>Более 15 огромных локаций.</li><li>Возможность заходить в дом, чтобы украшать свое поместье не только снаружи, но и внутри.</li><li>Более 100 хозяйственных построек и объектов антуража.</li><li>Более 15 различных типов животных со своими повадками.</li><li>Разветвленная система производства.</li><li>Возможность изменять ландшафт.</li><li>Сотни природных объектов, которые делают один пейзаж непохожим на другой.</li></ul>'
    },
    undercontrol: {
      description: '<p>Глобальная война создала новый миропорядок, и в новом мире правит тот, за кого говорит его сила. Возглавив военную базу, собрав сильнейшую армию и найдя единомышленников, тебе предстоит перекроить карту будущего мира.</p><p>Жанр: Стратегия<br>Сеттинг: Милитари</p>',
      features: '<ul><li>PVP стратегия в реальном времени.</li><li>Огромный выбор юнитов – пехота, тяжелая техника, артиллерия и авиация.</li><li>Нелинейная система улучшения войск.</li><li>Несколько режимов PVP боев – от 2 до 6 игроков в одном бою.</li><li>Выверенный баланс и самообучаемый механизм подбора противников.</li><li>Кланы и сражения за территории.</li><li>Ежемесячные игровые события с уникальными наградами.</li></ul>'
    },
    dungeonbrawlers: {
      description: '<p>Давным-давно, когда миром правили короли, а за сердца дам сражались смазливые рыцари, нормальные герои ходили за славой и золотом в подземелья. Соберите этих героев под вашими знаменами и отправляйтесь в самое захватывающее и эпичное приключение всех времен.</p><p>Жанр: Стратегия</p><p>Сеттинг: Фэнтэзи</p>',
      features: '<ul><li>5 классов персонажей – опасные воины, меткие охотники, могущественные маги, надежные защитники и хитроумные инженеры.</li><li>Более 15 уникальных героев.</li><li>Более 100 видов врагов – от крыс-переростков до пароботов и алхимиков с манией величия.</li><li>Бои с другими игроками, ежедневные рейтинги и еженедельные состязания.</li><li>Клановые бои и развитие кланов.</li><li>90 достижений и десятки титулов.</li><li>Более 150 сюжетных уровней в самых разных уголках королевства.</li></ul>'
    },
    jackandjill: {
      description: '<p>Джек и Джилл – это увлекательная история о приключениях маленьких человечков. В процессе игры тебе предстоит вместе с забавными жителями Паппетвиля вести хозяйство, украшать город и помочь отважному Джеку найти красавицу Джилл.</p><p>Сеттинг: Сказка</p>',
      features: '<ul><li>Более 20 уникальных персонажей, каждый – со своей историей.</li><li>Свыше 120 увлекательных заданий.</li><li>Полностью интерактивный мир, взаимодействуй со всем, что увидишь.</li><li>Более 50 уникальных построек и антуража.</li><li>Более 100 элементов ландшафта.</li><li>Продуманная система крафта и строительства.</li><li>Возможность менять облик персонажей, десятки костюмов на любой вкус.</li></ul>'
    },
    underfire: {
      description: '<p>Достигнув дальних миров, человечество столкнулось с тем, что навсегда его изменило. Кошмарные жуки Роя, восставший искусственный интелект, мутанты и собственная алчность бросили вызов людям. Присоединившись к Конфедерации Свободных миров, тебе предстоит отстроить космическую базу и вступить в войну за будущее всего человечества.</p><p>Сеттинг: Фантастика</p>',
      features: '<ul><li>Увлекательных сюжет, 6 сюжетных эпизодов, более 120 миссий.</li><li>Более 100 уникальных солдат и противников с индивидуальным поведением и специальными умениями.</li><li>Собственный космический флот и масштабные бои за колонии в космосе.</li><li>Развитая система прокачки войск и героев, более 150 технологий.</li><li>Несколько видов PVP-режимов.</li><li>Развитая система градостроительства – более 50 зданий и строений в колонии и на орбите.</li><li>Динамичные и зрелищные бои, где каждое твое решение способно изменить ситуацию и принести победу.</li></ul>'
    },
    undersiege: {
      description: '<p>Осада – наш взгляд на жанр tower defense. Встав во главе Королевства, тебе предстоит выдержать десятки яростных осад. К твоим услугам лучшие воины и именитые герои. Используй ловушки, хитрость и могущественную магию. Защити свое право на корону!</p><p>Жанр: Tower Defense</p>',
      features: '<ul><li>Tower defense в сеттинге классического фэнтези.</li><li>Десятки уникальных ловушек и солдат, могущественная магия и бравые герои позволят выстроить непреодолимую оборону.</li><li>Более сотни уникальных противников – от злобных гоблинов и безумных гномов до демонов, зомби и розовых слонов.</li><li>гонь, взрывы, молнии и страдания твоих врагов – сотни красочных эффектов не для слабонервных.</li><li>4 сюжетные компании, более 50 миссий с различным уровнем сложности, различные пейзажи от тропических островов до подземных сокровищниц.</li><li>Турниры и соревнования с игроками со всего света.</li><li>Более 10 игровых режимов, которые снесут тебе башню!</li></ul>'
    },
  },
  play_on: 'ИГРАТЬ ЧЕРЕЗ:',
  techs: 'Технологии:',
  features: 'ОСОБЕННОСТИ ИГРЫ',
  company: 'О компании',
  facts: {
    years: 'ЛЕТ В<br/>' +
        'ИНДУСТРИИ',
    projects: 'КРУПНЫХ<br/>' +
        'ПРОЕКТОВ',
    employees: 'СОТРУДНИКОВ<br/>' +
        'В КОМПАНИИ'
  },
  jobs: 'Вакансии',
  slogan: 'мы делаем игры,<br/> в которые хочется возвращаться!',
  occupation: [
    '',
    'Полная',
    'Неполная',
    'Аутсорс'
  ],
  location: [
    '',
    'Москва',
    'Лимасол',
    'Удалённая работа'
  ],
  deps: {
    "cat_1": {
      "name": "marketing",
      "description": "<p><strong>Мы верим что сделать игру - это только первая половина пути. Маркетинг и работа с сообществом могут сделать из хорошей игры легендарную.</strong></p><ul><li>Работаем с любыми каналами привлечения и получаем из них максимум.</li><li>Открыты к новым креативным концепциям в рекламе.</li><li>Ищем новые подходы в привлечении и вовлечении пользователей.</li><li>Поддерживаем дружественную атмосферу в сообществе и прислушиваемся к нашим пользователям.&nbsp;</li></ul>"
    },
    "cat_2": {
      "name": "gamedesign",
      "description": "<p><strong>В геймдизайне мы руководствуемся системным подходом, тщательно изучаем каждую идею, отбираем лучшие, и на их основе создаем интересные игры с продуманными механиками.</strong></p><ul><li>Нестандартные интересные задачи в области игровой логики, экономики и аналитики - скучно не будет.</li><li>Продуманная структура пайплайнов и адекватный баланс между креативом и использованием наработанных практик.</li><li>Возможность влиять на игровой продукт - отзывчивое руководство и продюсеры всегда готовы выслушать интересные обоснованные идеи.&nbsp;</li></ul>"
    },
    "cat_3": {
      "name": "art",
      "description": "<p><strong>Художники RJ Games – настоящие профессионалы, которые создают живые миры и запоминающихся героев, вдохновляя друг друга на новые достижения.</strong></p><ul><li>Fantasy, military или casual – художник может выбрать для себя проект с подходящим ему сеттингом и стилистикой.</li><li>Возможность освоить новые направления работы с графикой и поработать над интересными задачами в области концепт-арта, моделирования, анимации и UI.</li><li>Взаимопомощь и здоровая конкуренция в коллективе мастеров своего дела рождают результат, которым ты будешь гордиться.</li></ul>"
    },
    "cat_4": {
      "name": "development",
      "description": "<p><strong>Наши разработчики решают трудные задачи и достигают поставленных целей, благодаря чему проектами студии наслаждаются миллионы игроков по всему миру.</strong></p><ul><li>Разработчики самостоятельно выбирают пути решения задач, руководствуясь основным принципом – за каждой строкой кода должна стоять осознанная мысль.</li><li>Обмен опытом, совместное изучение новых технологий и непредвзятое отношение к свежему взгляду в обстановке профессионального доверия.</li><li>Наши эксперты не возводят разработку в культ, но и не относятся к ней халатно – они выбирают подходящие инструменты и создают надежные продукты.</li></ul>"
    },
    "cat_5": {
      "name": "administrative",
      "description": "<p><strong>Мы любим создавать уют и приятную атмосферу в наших офисах, чтобы любому участнику команды было комфортно не только поработать, но и отдохнуть в окружении коллег.</strong></p><ul><li>Гибкий подход к процессам – мы сторонники результатов, а не процедур ради процедур.</li><li>Сплоченная команда – мы всегда приходим друг другу на выручку, для нас не существует «проблем не на нашей стороне».</li><li>Простота и прозрачность – мы стараемся сделать все сложные процессы простыми, понятными и предсказуемыми для сотрудников.</li></ul>"
    }
  },
  contacts: {
    title: 'Контакты',
    cy: {
      location: 'Лимасол, Кипр',
      address: 'RJ Games LTD, HE 355950, 3 Agiou Neofytou, 3036'
    },
    ru: {
      location: 'Москва, Россия',
      address: 'Большая Татарская улица, д.35 стр. 7-9'
    },
  },
  terms: 'Terms of service',
  privacy: 'Privacy policy',
  social: 'Мы в соц.сетях:',
  social_project: 'Сообщества игры:',
  support: 'Поддержка',
  copyright: 'RJ Games LTD©️, '+ new Date().getFullYear(),
  next_game: 'Следующая игра',
  search: 'ПОИСК...',
  chosen_deps: 'выбранные направления:',
  all_deps: 'все направления',
  become_team: 'Стань частью команды!',
  become_jobs: 'Вакансии',
  share_job: 'Поделиться вакансией:',
  referral_program: 'Рекомендательная программа',
  ref_desc: 'RJ Games — это дружная команда профессионалов и заядлых геймеров. Любовь к своей профессии, взаимное уважение и доверие — ключевые элементы нашей культуры.<br/><br/>Многие наши сотрудники приходят в команду по рекомендациям (как внешним, так и внутренним). Рекомендуя сильных специалистов, вы помогаете им попасть в команду единомышленников и получаете приятный финансовый бонус!',
  ref_reward: 'Вознаграждение за рекомендацию',
  ref_rub_any: 'за рекомендацию на любую позицию',
  ref_rub_special: 'за рекомендацию на горящую вакансию,<br/>которая отмечена значком',
  ref_bonus: 'Выплата бонусов',
  ref_bonus_text: '<li>Рекомендация принимается, если компания ранее не общалась с рекомендованным кандидатом, и он никогда ранее не рассматривался.</li><li>Срок действия принятой рекомендации - 1 год с момента отправки рекомендации через специальную форму.</li><li>Бонус за рекомендацию релевантного специалиста выплачивается в 100% размере после прохождения испытательного срока.</li>',
  ref_howto: 'Как принять участие в программе?',
  ref_howto_text: 'Если вы хотите порекомендовать специалиста, пожалуйста, заполните <a target="_blank" href="https://forms.gle/WX8vZTTjJApHn6jq9">форму</a>. Если вы не уверены, на какую позицию может претендовать ваш знакомый, просто оставьте поле пустым, а мы подберем подходящий вариант.<br/><br/>Список актуальных вакансий размещён в разделе <a href="/vacs">вакансии</a>.<br/><br/>Любые вопросы по бонусной программе вы можете отправить на почту <a href="mailto:job@rj-games.com">job@rj-games.com</a>',
  ref_vacs: 'Вакансии',
  job: {
    salary: 'Зарплата',
    schedule: 'Занятость',
    location: 'Офис'
  },
  available: 'уже доступно:',
  available2: 'уже доступно!',
  more: 'Подробнее об игре',
  play_in: 'играть в Puzzle Breakers:',
  closed: 'Эта игра уже закрыта, но навсегда останется важной частью нашей истории.<br>А новые приключения ждут вас в' +
      ' <b><a href="https://play.google.com/store/apps/details?id=com.playrix.puzzlebreakers" target="_blank">Puzzle' +
      ' Breakers!</a></b>'
};
