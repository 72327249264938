import * as React from 'react';
import config from '../mixins/config';
import Footer from '../components/Footer';
import Slider from '../components/projects/Slider';
import { Link } from 'react-router-dom';
import ExternalLink from '../components/ExternalLink';

interface MyProps {
    match: any,
    strings: any,
    language: any
};

interface MyState {
    width: number,
    shift: number
};

class Project extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            width: 1004 * 5,
            shift: 0
        };
    }

    // public componentDidMount() {
    // this.setState({
    //   width: 1004 * 5,
    // });
    // }

    createMarkup(string: string) {
        return { __html: string };
    }

    public render() {
        window.scrollTo(0, 0);
        const project = config.projects.find((item) => item.link === this.props.match.params.id);
        let nextGame: any = '';
        let videos, bottom_video;
        let nextProject = '';

        if (project.id === 7) {
            nextProject = 'puzzlebreakers';
        }
        else {
            nextProject = config.projects[project.id + 1].link;
        }

        nextGame = <Link key={ project.id } to={ '/project/' + nextProject }>{ this.props.strings.next_game }</Link>;
        // nextGame = <a href={`/` + nextProject}>{this.props.strings.next_game}</a>;

        let projectStrings = this.props.strings.projects_list[this.props.match.params.id];
        let techs = project.tech.join(', ');

        if (project.videos.length > 0) {
            videos = <video playsInline autoPlay poster={ project.main_image } loop className="video-item">
                { project.videos.map((item) => {
                    return <source key={ item.type } type={ item.type } src={ item.src }/>;
                }) }
            </video>;
        }
        else {
            videos = <img className="video-item" src={ project.main_image } alt=""/>;
        }

        if (project.bottom_videos.length > 0) {
            let currentVideoItem = project.bottom_videos[Math.floor(Math.random() * 3)];
            bottom_video =
                <video playsInline autoPlay muted loop className="video-item">
                    { currentVideoItem.map((item) => {
                        return <source key={ item.type } type={ item.type } src={ item.src }/>;
                    }) }
                </video>;
        }
        else {
            bottom_video = <img src={ project.bottom_image } alt=""/>;
        }

        let socialBlockClass = project.social.length > 0 ? 'social show' : 'social';
        let social = project.social.map((item) => {
            return <li key={ Object.keys(item)[0] } className={ Object.keys(item)[0] }><a rel="noopener noreferrer"
                                                                                          target="_blank"
                                                                                          href={ item[Object.keys(
                                                                                              item)[0]] }>&nbsp;</a>
            </li>;
        });
        return (
            <React.Fragment>
                <div id="Project">
                    <div className="content">
                        { project.id !== 5 &&
                            <div className="main_image">
                                <div className="video-container">
                                    { videos }
                                </div>
                            </div>
                        }
                        <div className="inner">
                            <div className="content">
                                <div className="description">

                                    <div className="image">
                                        <img src={ project.desc_image } alt=""/>
                                    </div>

                                    <div className="descr">
                                        <div className={ 'name' }>
                                            { project.front.name }
                                        </div>
                                        { project.id === 0 &&
                                            <div className={ 'available' }>
                                                { project.front.button_link_ios &&
                                                    <div className={ 'play_link_ios' }><a href={ project.front.button_link_ios }
                                                                                      target={ '_blank' }><img
                                                        src="/img/btn_play_ios_black.png" alt=""/></a></div>
                                                }

                                                <div className={ 'play_link' }><a href={ project.front.button_link }
                                                                                  target={ '_blank' }><img
                                                    src="/img/btn_play_gp_black.png" alt=""/></a></div>
                                                <div
                                                    className={ 'available_text' }>{ this.props.strings.available2 }</div>
                                            </div>
                                        }
                                        { project.id === 3 &&
                                            <div className={ 'available' }>

                                                <div className={ 'play_link' }><a href={ project.front.button_link }
                                                                                  target={ '_blank' }><img
                                                    src={ '/img/btn_play_en.png' } alt=""/></a>
                                                </div>
                                                <div
                                                    className={ 'available_text' }>{ this.props.strings.available2 }</div>
                                            </div>
                                        }
                                        { project.id !== 3 && project.id !== 0 &&
                                            <div className={ 'not_available' }>
                                                <div className={ 'available_text' }
                                                     dangerouslySetInnerHTML={ this.createMarkup(
                                                         this.props.strings.closed) }/>
                                                <div className={ 'play_link' }>
                                                    <div className={ 'play_in' }>{ this.props.strings.play_in }</div>
                                                    <div className="play_buttons">
                                                        <div className="button">
                                                            <a href="https://apps.apple.com/us/app/puzzle-breakers-rpg-online/id1523290976"
                                                               target={ '_blank' }><img
                                                                src={ '/img/btn_play_ios_black.png' } alt=""/></a>
                                                        </div>
                                                        <div className={ 'play_button' }>
                                                            <a href="https://play.google.com/store/apps/details?id=com.playrix.puzzlebreakers"
                                                               target={ '_blank' }><img
                                                                src={ '/img/btn_play_gp_black.png' } alt=""/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="text"
                                             dangerouslySetInnerHTML={ this.createMarkup(projectStrings.description) }>

                                        </div>
                                        <div className="techs">{ this.props.strings.techs }&nbsp;{ techs }</div>
                                    </div>
                                </div>
                                <Slider project={ project.id }/>
                                <h2 dangerouslySetInnerHTML={ this.createMarkup(this.props.strings.features) }/>
                                <div className="features">
                                    <div className="text"
                                         dangerouslySetInnerHTML={ this.createMarkup(projectStrings.features) }/>
                                    <div className={ 'image image_' + project.id }>
                                        { bottom_video }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer project={ project.id } strings={ this.props.strings } language={ this.props.language }/>
            </React.Fragment>
        );
    }
}

export default Project;
