import * as React from 'react';
import './styles/App.scss';
import {Route, BrowserRouter as Router} from "react-router-dom";
import Navbar from "./ts/components/Navbar";
import Home from "./ts/routes/Home";
import Job from "./ts/routes/Job";
import Project from "./ts/routes/Project";
import { Vacancies } from './ts/routes/Vacancies';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {strings} from './ts/components/Localization';
import axios from 'axios';

const urlParams = new URLSearchParams(window.location.search);
const lan = urlParams.get('lan');

strings.setLanguage('en');

if (!!lan) {
  strings.setLanguage(lan);
}

library.add(fas);

interface MyState {
  isVacs: boolean
};

interface MyProps {

};

class App extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.state = {
      isVacs: true
    }
    this.getVacs();
  }

  getVacs() {
    const url = '/data.json';
    const _self = this;
    return axios.get(url)
    .then((res) => {
      if (res.data.length < 1) {
        _self.setState({
          isVacs: false
        })
      }
    })
    .catch(function (error) {
      console.log(error);
      _self.setState({
        isVacs: false
      })
    })
  }

  // componentDidMount() {
  //   this.getVacs();
  // };

  toggleLanguage() {
    if (strings.getLanguage() === 'en') {
      strings.setLanguage('gr');
    }
    else {
      strings.setLanguage('en');
    }
    this.setState({});
  }

  public render() {
    let props = {
      strings: strings,
      language: strings.getLanguage(),
      isVacs: this.state.isVacs
    };

    return (
        <div id="container">
          <Router>
            <Navbar strings={strings} isVacs={props.isVacs} language={props.language} toggleLanguage={this.toggleLanguage}/>
            <div className="main">
              <Route path="/" exact render={(routeProps) => (
                  <Home {...routeProps} {...props} />
              )}/>
              <Route path={`/project/:id`} exact render={(routeProps) => (
                  <Project {...routeProps} {...props} />
              )}/>
              <Route path={`/vacs`} exact render={(routeProps) => (
                  <Vacancies {...routeProps} {...props} />
              )}/>
              <Route path={`/job/:id`} render={(routeProps) => (
                  <Job {...routeProps} {...props} />
              )}/>
            </div>
          </Router>
        </div>
    );
  }
}

export default App;
