import * as React from 'react';
import Slider from "../components/Slider"
import Projects from "../components/Projects"
import Company from "../components/Company";
import Workus from "../components/Workus";
import {JobsText} from "../components/jobs-text/jobs-text";
import Footer from "../components/Footer";
import Contacts from "../components/Contacts";

interface MyProps {
  strings: any,
  isVacs: any,
  language: any
};

class home extends React.Component<MyProps> {
  public render() {
    // let jobs = !!this.props.isVacs ? <Jobs strings={this.props.strings} language={this.props.language}/> : '';
    return (
        <React.Fragment>
          <Slider strings={this.props.strings} language={this.props.language}/>
          <Projects strings={this.props.strings} language={this.props.language}/>
          <Company strings={this.props.strings} language={this.props.language}/>
          <Workus strings={this.props.strings} language={this.props.language}/>
          <Contacts strings={this.props.strings} language={this.props.language}/>
          <Footer project={''} strings={this.props.strings} language={this.props.language}/>
        </React.Fragment>
    );
  }
}

export default home;
