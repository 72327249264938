import * as React from 'react';
import config from '../../mixins/config';

interface MyProps {
    project: string
}

interface MyState {
    width: number,
    shift: number,
    active: number,
    touchStart: number
    touchCurrent: number
}

function mobileAndTabletcheck() {
    var check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
                a.substr(0, 4))) {
            check = true;
        }
    })(navigator.userAgent || navigator.vendor);
    return check;
};

let slideWidth = !!mobileAndTabletcheck() ? window.screen.availWidth - (window.screen.availWidth / 100 * 8)  : 990;

class Slider extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.goLeft = this.goLeft.bind(this);
        this.goRight = this.goRight.bind(this);
        this.goCurrent = this.goCurrent.bind(this);
        this.state = {
            width: 0,
            shift: 0,
            active: 0,
            touchStart: 0,
            touchCurrent: 0
        };
    }

    public componentDidMount() {
        let count = config.projects[this.props.project].slider.length;
        this.setState({
            width: slideWidth * count
        })
    }

    goLeft() {
        let shift;
        let active;
        if (this.state.shift < 0) {
            shift = this.state.shift + slideWidth;
            active = this.state.active - 1;
            this.setState({
                shift: shift,
                active: active
            })
        }

    }

    goRight() {
        let shift;
        let active;
        if (-this.state.shift < this.state.width - slideWidth) {
            shift = this.state.shift - slideWidth;
            active = this.state.active + 1
        }
        else {
            shift = 0;
            active = 0;
        }
        this.setState({
            shift: shift,
            active: active
        })
    }

    goCurrent(i: number) {
        let shift = -(i * slideWidth);
        this.setState({
            shift: shift,
            active: i
        })
    }

    handleTouchStart(e) {
        const touch = e.touches[0].clientX;
        this.setState({
            touchStart: touch
        })
    }

    handleTouchMove(e) {
        const touch = e.touches[0].clientX;
        this.setState({
            touchCurrent: touch
        })
    }

    handleTouchEnd(e) {

        const shift = this.state.touchCurrent - this.state.touchStart;
        if (shift > 0) {
            this.goLeft();
        }
        else {
            this.goRight();
        }
        this.setState({
            touchStart: 0,
            touchCurrent: 0
        })
    }

    public render() {
        let bullets: any = [];
        let slides: any = [];
        const project = config.projects.filter((project) => project.id === this.props.project)[0];
        const { slider } = project;
        for (let i = 0; i < slider.length; i++) {
            let className = 'bull';
            if (i === this.state.active) {
                className = className + ' active';
            }
            bullets.push(<div key={ i } className={ className } onClick={ () => {this.goCurrent(i)} }/>);
            slides.push(<img key={ i } src={ slider[i] } alt=""/>);
        }
        let style = {
            width: this.state.width,
            marginLeft: this.state.shift
        };
        return (
            <div className="slider">
                <div className="controls">
                    <div className="left" onClick={ this.goLeft }>&nbsp;</div>
                    <div className="right" onClick={ this.goRight }>&nbsp;</div>
                </div>
                <div className="slides">
                    <div className="container" style={ style }
                         onTouchStart={ touchStartEvent => this.handleTouchStart(touchStartEvent) }
                         onTouchEnd={ touchEndEvent => this.handleTouchEnd(touchEndEvent) }
                         onTouchMove={ touchMoveEvent => this.handleTouchMove(touchMoveEvent) }>
                        { slides }
                    </div>
                </div>
                <div className="bullets">
                    { bullets }
                </div>
            </div>
        );
    }
}

export default Slider;
