import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface MyState {
    className: string,
    showLogo: boolean,
    menuStyle: any,
    menuStyleFlag: boolean,
    location: string
}

interface MyProps {
    toggleLanguage: any,
    strings: any,
    isVacs: any,
    language: any
}

class Navbar extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            className: '',
            showLogo: false,
            menuStyle: {},
            menuStyleFlag: false,
            location: document.location.pathname + document.location.hash
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if (window.location.pathname !== '/') {
            this.setState({
                showLogo: true
            })
        }
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll() {
        let scrollTop = window.scrollY;
        this.setState({
            className: scrollTop > 0 ? 'scrolled' : '',
            showLogo: scrollTop > 500 || window.location.pathname !== '/' ? true : false
        });
        if (scrollTop > 3800) {
            this.setState({
                location: '/#Contacts'
            });
        }
        else if (scrollTop > 3500) {
            this.setState({
                location: '/#Jobs'
            });
        }
        else if (scrollTop > 2314) {
            this.setState({
                location: '/#Workus'
            });
        }
        else if (scrollTop > 1700) {
            this.setState({
                location: '/#Company'
            });
        }
        else if (scrollTop > 830) {
            this.setState({
                location: '/#Projects'
            });
        }
        else {
            this.setState({
                location: '/'
            });
        }
    };

    switchLanguage(context: any) {
        context.props.toggleLanguage();
    }

    showMenu() {
        this.setState({
            menuStyleFlag: true,
            menuStyle: {
                opacity: 1,
                transition: 'opacity 0.3s ease-in-out',
                display: 'block'
            }
        })
    }

    hideMenu() {
        if (this.state.menuStyleFlag) {
            this.setState({
                menuStyleFlag: false,
                menuStyle: {
                    opacity: 0,
                    transition: 'opacity 0.3s ease-in-out',
                    display: 'none'
                }
            })
        }
    }

    toggleMenu() {
        if (this.state.menuStyleFlag) {
            this.hideMenu();
        }
        else {
            this.showMenu();
        }
    }

    public render() {
        let lang = this.props.language === 'en' ? 'gr' : 'en';
        let strings = this.props.strings;
        let vacs = !!this.props.isVacs ? <li><Link onClick={ () => this.hideMenu() }
                                                   to={ `/vacs` }>{ strings.jobs }</Link></li> : '';
        return (
            <div id='Navbar' className={ this.state.className }>
                <div className="content">
                    <div onClick={ () => this.hideMenu() } className={ 'logo ' + this.state.showLogo }>
                        <NavLink smooth to={ `/#Slider` }><img src="/img/logo_big.svg" alt=""/></NavLink>
                    </div>
                    <div className={ 'lang' } onClick={ () => this.switchLanguage(this) }>
                        { lang }
                    </div>
                    <div className={ 'burger' } onClick={ () => this.toggleMenu() }>
                        <FontAwesomeIcon icon='bars'/>
                    </div>
                    <ul className="menu" style={ this.state.menuStyle }>
                        <li><NavLink onClick={ () => this.hideMenu() } smooth activeClassName="active"
                                     location={ { pathname: this.state.location } }
                                     to={ `/#Projects` }>{ strings.projects }</NavLink></li>
                        <li><NavLink onClick={ () => this.hideMenu() } smooth activeClassName="active"
                                     location={ { pathname: this.state.location } }
                                     to={ `/#Company` }>{ strings.company }</NavLink></li>
                        <li><NavLink onClick={ () => this.hideMenu() } smooth activeClassName="active"
                                     location={ { pathname: this.state.location } }
                                     to={ `/#Contacts` }>{ strings.contacts.title }</NavLink></li>
                        { vacs }
                        <li>
                            <div className="mobile_lang">
                                <div className={ 'lang' } onClick={ () => this.switchLanguage(this) }>
                                    { lang }
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Navbar;
