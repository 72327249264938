import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import ru from './locales/loc_ru'
import en from './locales/loc_en'
import grk from './locales/loc_grk'

export const strings: LocalizedStringsMethods = new LocalizedStrings({
  ru: ru,
  en: en,
  gr: grk
});
